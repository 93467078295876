export default (state = [], payload) => {
    switch(payload.type){
        case 'INSERT_TRABALHADORES_SELECT':
            if(payload.data.length){
                state = payload.data
                return state
            }else{
                state = []
                return state
            }
        case 'ADD_TRABALHADORES_SELECT':
            state = state.push(payload.data)
            return state
        case 'FIND_TRABALHADOR_SELECT':
            let cpf = payload.data
            let resultTrabalhador = state.filter((el) => {
                if(cpf === el.cpf_tbldr){
                    return true
                }
            })
            return resultTrabalhador
        case "REMOVE_TRABALHADORES_SELECT":
            let result = state.splice(payload, 1)
            state = result
            return state
        case "CLEAR_TRABALHADORES_SELECT":
            state = []
            return state
        default:
            return state
    }
}
