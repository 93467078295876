import React from 'react'
import { Button } from 'react-materialize'
import $ from 'jquery'
import * as Firebase from '../helpers/firebase'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom";
import Base64 from 'base-64'
import Cookie from 'js-cookie'
import { Row, Col, Icon, Modal, Switch } from "antd";
import * as Socket from '../helpers/socket'
import anexo from '../_assets/img/attachment.png'
import Trabalhadores from '../Components/Trabalhadores'
import SelectEmpresa from '../Components/SelectEmpresa.js'

//ACTIONS
import * as TrabalhadoresActions from '../actions/trabalhadoresAction.js'
import * as TrabalhadoresSelectActions from '../actions/trabsSelectActions.js'
import * as SelectStateActions from '../actions/SelectStateAction.js'

//Icons
import IconSend from "../_assets/img/right-arrow.png"

class CreateMessagem extends React.Component {
    constructor(props) {
        super(props)

        this.readyModal = this.readyModal.bind(this)
        this.createMessage = this.createMessage.bind(this)
        this.removeUserList = this.removeUserList.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.createGroup = this.createGroup.bind(this)
        this.viewButtonGroup = this.viewButtonGroup.bind(this)
        this.viewButtonMsg = this.viewButtonMsg.bind(this)

        this.state = {
            redirect: false,
            isOpened: false,
            session: JSON.parse(Base64.decode(Cookie.get('conecta_session'))),
            listaSend: [],
            listGroups: [],
            fileUpload: "",
            spin: false,
            switchButton: false
        }
        this.session = JSON.parse(Base64.decode(Cookie.get('conecta_session')))
        this.addGroupToSend = this.addGroupToSend.bind(this)
        this.toggleBox = this.toggleBox.bind(this)



    }

    readyModal() {
        if (this.props.selectTrabs.length === 0) {
            $('#box-send-new').modal('close')
        } else {
            let restoAltura = $('#box-send-new').height() - ($('#box-send-new .to').height() + 310)
            $('#menssage-all').height(restoAltura)
        }

        $(window).resize(() => {
            let restoAltura = $('#box-send-new').height() - ($('#box-send-new .to').height() + 310)
            $('#menssage-all').height(restoAltura)
        })
    }

    closeModal() {
        $(".buttonClose").trigger("click");
    }

    removeUserList(e) {
        console.log(this.props)
        let index = e.target.getAttribute('index')
        let arr = this.props.selectTrabs.slice(0)

        $("#filled-in-box" + arr[index].originalIndex).prop("checked", false)

        arr.splice(index, 1)
        this.props.action.insertSelectTrabalhadores(arr)

        if (arr.length === 0) {
            this.closeModal()
        }
    }

    createMessage(e) {
        e.preventDefault()
        let submit = {}
        let message = {
            user: {
                _id: 0
            },
            seen: {
                cnpj: true,
                cpf: false
            },
            text: e.target.message.value
        }
        if (this.props.session.type == 'entd') {
            message.name = this.session.entidade.razao_entd
            message.dataInfo = {
                id: this.session.entidade.id_entd,
                doc: this.session.entidade.cnpj_entd,
            }
        } else {
            message.name = this.session.operador.nome_empr_oprdr
            message.dataInfo = {
                id: this.session.operador.id_empr_oprdr,
                doc: this.session.operador.cpf_empr_oprdr,
            }
        }

        if (this.state.fileUpload != '') {
            let typeFile = this.state.fileUpload.split('.').pop()
            let onlyImg = ['jpeg', 'png', 'jpg', 'gif']
            if (onlyImg.indexOf(typeFile) > -1) {
                message.image = this.state.fileUpload
            } else {
                message.arquivo = this.state.fileUpload
            }
        }


        let channel = {
            group: false,
            noreply: this.state.switchButton,
            seen: {
                cnpj: true,
                cpf: false
            },
            subject: e.target.subject.value
        }

        let docs = []

        if (this.props.session.type === 'empr') {
            let empr = this.props.session.empresa

            channel.cnpj = empr.cnpj_empr
            channel.employer = empr.razao_empr
        } else {
            let entd = this.props.session.entidade
            channel.cnpj = entd.cnpj_entd
            channel.employer = entd.razao_entd
        }

        let dataArr = {
            insert: [],
            message: message,
            type: 'insert-batch'
        }

        this.props.selectTrabs.map((el, index) => {
            let channelEl
            if (this.props.session.type != 'empr') {
                let entd = this.props.session.entidade

                channelEl = Object.assign({
                    users: [{
                        cpf: el.cpf_tbldr || el.cpf,
                        name: el.nome_tbldr || el.name
                    }],
                }, channel)

                docs.push(entd.cnpj_entd)
            } else {
                channelEl = Object.assign({
                    users: [{
                        cpf: el.cpf_tbldr || el.cpf,
                        name: el.nome_tbldr || el.name
                    }],
                }, channel)

                docs.push(el.cpf)
            }

            dataArr.insert.push(channelEl)
        })

        /**
         * Insere os channels para os usuarios
         */
        Socket.setChannel(dataArr)

        //send push para o usuario
        Firebase.sendPush(docs, this.props.session.token)
        this.props.action.clearSelectTrabalhadores()

        if (this.props.toogleSelectTrabalhadore) {
            this.props.action.toogleState()
        }

        e.target.reset()

        this.setState({
            redirect: true
        })
    }

    createGroup(e) {
        e.preventDefault()
        let channel = {
            group: true,
            seen: {
                cnpj: true,
                cpf: false

            },
            subject: e.target.subject.value,
            description: e.target.message.value
        }
        let submit
        let docs = []

        if (this.props.session.type === 'empr') {
            let empr = this.props.session.empresa
            channel.cnpj = empr.cnpj_empr
            channel.employer = empr.razao_empr
        } else {
            let entd = this.props.session.entidade
            channel.cnpj = entd.cnpj_entd
            channel.employer = entd.razao_entd
        }
        let dataArr = {
            insert: [],
            message: {
                text: 'Grupo Criado',
                user: {
                    _id: 0
                }
            },
            type: 'insert-batch'
        }



        let users = []

        this.props.selectTrabs.map((el, index) => {
            let channelEl

            if (typeof el === 'number') {
                /*channelEl = Object.assign({
                    users: [{
                        cpf: index.cpf_tbldr,
                        name: index.nome_tbldr
                    }],
                }, channel)*/
                users.push({
                    cpf: index.cpf_tbldr,
                    name: index.nome_tbldr

                })
                docs.push(index.cpf_tbldr)
            } else {

                users.push({
                    cpf: el.cpf_tbldr,
                    name: el.nome_tbldr

                })
                docs.push(el.cpf_tbldr)
            }

        })
        //dataArr.insert.push(channelEl)
        channel.users = users

        dataArr.insert.push(channel)
        /**
         * Insere os channels para os usuarios
         */

        Socket.setChannel(dataArr)



        //send push para o usuario
        Firebase.sendPush(docs, this.props.session.token)
        this.props.action.clearSelectTrabalhadores()

        if (this.props.toogleSelectTrabalhadore) {
            this.props.action.toogleState()
        }

        e.target.reset()

        this.closeModal()


        $(".back-list-group button").trigger('click')

        /*this.setState({
            redirect: true
        })
*/

        // Exibir Confirmação de envio




    }

    viewButtonGroup(url) {
        if (url === '/lista-de-envio' || url === '/criar-grupo') {
            return {
                display: 'inline'
            }
        } else {
            return {
                display: 'none'
            }
        }
    }

    viewButtonMsg(url) {
        if (url === '/criar-grupo') {
            return {
                display: 'none'
            }
        } else {
            return {
                display: 'inline'
            }
        }
    }

    addGroupToSend(element, e) {
        let data = Object.assign(this.state.listaSend)
        if (e.target.checked) {
            let lista = Object.keys(element.employees).map((el, index) => {
                return element.employees[el]
            })

            data = data.concat(lista)
            this.props.action.insertSelectTrabalhadores(data)
            this.setState({
                listaSend: data
            })

        } else {
            Object.keys(element.employees).map((key) => {
                data.splice(element.employees[key], 1)
            })
            this.props.action.insertSelectTrabalhadores(data)

            this.setState({
                listaSend: data
            })
        }
    }
    // Sele
    toggleBox() {

        const { isOpened } = this.state;
        this.setState({
            isOpened: !isOpened,
        });
    }

    buttonSwitch = () => {
        const { switchButton } = this.state;
        this.setState({
            switchButton: !switchButton
        })
    }

    openFile = () => {
        let inputFile = document.getElementById("fileAttachment")
        inputFile.click()
    }

    sendFile = () => {
        let el = document.querySelector("#fileAttachment")
        let formData = new FormData();

        formData.append('file', el.files[0]);
        this.setState({
            spin: true
        })
        fetch('https://cobranca.bsfonline.com.br/api-v1/uploads/images', {
            headers: {
                'Accept': 'application/json'
            },
            method: 'POST',
            body: formData
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    fileUpload: resp.location,
                    spin: false
                })
                Modal.success({
                    title: 'Arquivo',
                    content: 'upload efetuado com sucesso!'
                })
            }).catch((e) => {
                this.setState({
                    spin: false
                })
                Modal.error({
                    title: 'Erro',
                    content: 'Por favor, insira novamente o arquivo',
                });
            })
    }

    clearFileUpload = () => {
        this.setState({
            fileUpload: ""
        })
        Modal.success({
            title: "Upload",
            content: "Arquivo removido com sucesso!"
        })
    }

    render() {

        let perfil = null
        const { isOpened } = this.state;
        const { redirect } = this.state;

        const ButtonGroupView = {
            display: 'none'
        }

        if (this.state.session.type === 'empr') {
            perfil = <Trabalhadores ref={instance => this.trabalhadoresRef = instance} session={this.state.session} url={this.props.url} />
        } else {
            perfil = <SelectEmpresa session={this.state.session} url={this.props.url} />
        }
        if (redirect) {
            return <Redirect to="/chat" />
        }
        let blockButtom = this.props.selectTrabs.length !== 0 ? false : true
        let blockButtomGrupo = this.props.selectTrabs.length > 1 ? false : true
        let paraSend = this.props.selectTrabs.map((el, el2) => {
            if (typeof el === 'number') {
                return <span key={el} className='to-single badge blue white-text cursor' title={el2.cpf_tbldr || el2.cpf}>
                    {el2.nome_tbldr || el2.name}
                    <i onClick={this.removeUserList} index={el} className="fa fa-times-circle" aria-hidden="true"></i>
                </span>
            } else {
                return <span key={el2} className='to-single badge blue white-text cursor' title={el.cpf_tbldr || el.cpf}>
                    {el.nome_tbldr || el.name}
                    <i onClick={this.removeUserList} index={el2} className="fa fa-times-circle" aria-hidden="true"></i>
                </span>
            }
        })

        return (<div className="cp-send">
            {this.props.url != '/criar-grupo' ? (
                <div id="box-send-new">
                    <h6>ENVIAR MENSAGEM PARA O TRABALHADOR</h6>
                    <p><img className='buttonAttachment animated' id="bt-files" onClick={this.openFile} src={anexo} /></p>
                    <input type="file" name="anexo" id="fileAttachment" onChange={this.sendFile} />

                    <label className='label-to'>
                        Para: <span className="total">{this.props.selectTrabs.length}</span>
                    </label>
                    <div className="to">
                        {paraSend}
                    </div>
                    <form action="#" id="form-create-message" onSubmit={this.createMessage}>
                        <input type="text" placeholder="Assunto" id="assuntr-all" required="" name="subject" />
                        <textarea name="message" id="menssage-all" cols="30" rows="30" placeholder="DIGITE UMA MENSAGEM" required></textarea>
                        {/* <span style={{display:'flex', alignItems:'flex-end',color: '#666', float:'left'}}>BLOQUEAR RESPOSTAS: <Switch style={{marginLeft:'10px'}}  onChange={this.buttonSwitch} /></span> */}
                        <button className="btn-send-trabalhador" type="submit">
                            <img src={IconSend}/>
                        </button>
                    </form>
                    {this.state.spin && (<Icon type="sync" spin />)}
                    {this.state.fileUpload && (<div className="send-attachment">
                        <Icon type="paper-clip" /> Nome do arquivo.png ou doc <Icon type="close-circle" onClick={this.clearFileUpload} />
                    </div>
                    )}

                    <Button className="buttonClose" modal="close">x</Button>
                </div>

            ) : (
                    <div
                        id="box-group-new"
                        fixedFooter={false}
                        modalOptions={{
                            ready: this.readyModal
                        }}
                        trigger={
                            <Button className="ant-btn ant-btn-primary btn-send btn-sendcollection-item avatar open-chat hash"
                                style={
                                    this.viewButtonGroup(this.props.url)
                                }
                                disabled={blockButtomGrupo}> CRIAR GRUPO + </Button>}>
                        <h6>CRIANDO GRUPO</h6>
                        <label className='label-to'>
                            Participantes: <span className="total">{this.props.selectTrabs.length}</span>
                        </label>
                        <div className="to">
                            {paraSend}
                        </div>
                        <form action="#" id="form-create-message" onSubmit={this.createGroup}>
                            <input type="text" placeholder="Nome do Grupo" id="assuntr-all" required="" name="subject" />
                            <textarea name="message" id="menssage-all" cols="30" rows="10" placeholder="Descriação" required=""></textarea>
                            <button className="btn-floating btn-large waves-effect waves-light send send-all bt-blue" type="submit">
                                <img src={IconSend}/>
                            </button>
                        </form>
                        <Button className="buttonClose" modal="close">x</Button>
                    </div>
                )}

        </div>
        )




    }
}

function mapStateProps(state) {
    return {
        trabalhadores: state.trabalhadores,
        selectTrabs: state.trabsSelect,
        toogleSelectTrabalhadore: state.toogleSelectTrabalhadore
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators(Object.assign(TrabalhadoresActions, TrabalhadoresSelectActions, SelectStateActions), dispatch)
    }
}

export default connect(mapStateProps, mapDispatchProps)(CreateMessagem)
