export default (state = false, payload) => {
    switch(payload.type){
        case 'CREATE_GROUP_PAGE_VIEW':
            state = payload.data
            return state

        default:
            return state
    }
}
