import React from 'react'


class CreateList extends React.Component{
    constructor(props){
        super(props)
    }


    render(){
        return(
            <div className='container-list'>
                    <h5>CRIAR LISTA DE GRUPO</h5> 
            </div>
        )
    }
}
export default CreateList
