import React from "react";
import Base64 from "base-64";
import Cookie from "js-cookie";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CreateMessage from "./CreateMessage";
import $ from "jquery";
import Trabalhadores from "./Trabalhadores.js";
import * as Helpers from "../helpers/Helpers.js";
import Paginate from "./Paginate.js";
import {
  Layout,
  Menu,
  Icon,
  Button,
  Radio,
  Switch,
  Col,
  Row,
  Pagination,
  Skeleton,
  Checkbox
} from "antd";
import { Scrollbars } from "react-custom-scrollbars";

import TrabalhadorEntidade from "../Components/EntidadeTrabalhadorBusca";

//actions
import * as EmpresaActions from "../actions/empresaAction";
import * as TrabalhadoresSelectActions from "../actions/trabsSelectActions.js";

class SelectEmpresa extends React.Component {
  constructor(props) {
    super(props);
    this.trabalhadoresRef = null;
    this.state = {
      backupAll: [],
      page: "empresa",
      paginate: 1,
      totalPaginateNumber: 1,
      maxPerPage: 20,
      search: false,
      empresas: [],
      selectEmpresa: {},
      trabalhadores: [],
      change: false,
      totalResult: 0,
      onlyApp: true,
      listaSend: [],
      buscaEmpresa: false
    };
    this.busca = this.busca.bind(this);
    this.goTrabs = this.goTrabs.bind(this);
    this.changePage = this.changePage.bind(this);
    this.listAll = this.listAll.bind(this);
    this.updatePaginate = this.updatePaginate.bind(this);
    this.limparPesquisa = this.limparPesquisa.bind(this);
    this.acronymName = this.acronymName.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onlyActive = this.onlyActive.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.addTrabalhador = this.addTrabalhador.bind(this);
    this.typeSearch = this.typeSearch.bind(this);
    console.log("props.selectEMpresa", this.props);
  }

  /**
   * Incrementa ou decrementa as paginação
   */
  updatePaginate(value) {
    this.setState({
      paginate: value
    });
  }

  /**
   * lista de empresas tras todas as empresas
   * relacionadas ao sidicato.
   */
  async listAll() {
    let self = this;
    try {
      let res = await fetch("/empresas", {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: self.props.session.token
        }
      });

      res = await res.json();

      let totalNum = Helpers.totalPage(
        res.content.empresas.length,
        this.state.maxPerPage
      );
      this.setState({
        backupAll: res.content.empresas,
        empresas: res.content.empresas,
        totalPaginateNumber: totalNum === 0 ? 1 : totalNum,
        paginate: 0
      });
    } catch (e) {}
  }

  async componentWillMount() {
    this.listAll();
  }

  componentWillReceiveProps(props, oldProps) {
    this.setState({
      listaSend: props.selectTrabs
    });
  }

  async onChange(page, pageSize) {
    let self = this;

    try {
      self.setState({
        change: true
      });
      let res = await fetch(
        "https://api.bsfonline.com.br/trabalhadores/todos-por-entidade",
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: self.props.session.token
          },
          body: JSON.stringify({ page: page, ativos: self.state.onlyApp })
        }
      );

      res = await res.json();
      self.setState({
        trabalhadores: res.content,
        change: false,
        totalResult: res.total
      });
    } catch (e) {
      console.log(e);
    }
  }

  async componentDidMount() {
    let self = this;
    try {
      self.setState({
        change: true
      });
      let res = await fetch(
        "https://api.bsfonline.com.br/trabalhadores/todos-por-entidade",
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: self.props.session.token
          },
          body: JSON.stringify({ ativos: self.state.onlyApp })
        }
      );

      res = await res.json();
      self.setState({
        trabalhadores: res.content,
        change: false,
        totalResult: res.total
      });
    } catch (e) {
      console.log(e);
    }
  }

  async busca(e) {
    e.preventDefault();
    let self = this;
    let busca = e.target.busca.value;

    self.setState({
      change: true
    });

    if (self.state.buscaEmpresa) {
      try {
        let res = await fetch(
          "https://api.bsfonline.com.br/empresas/?s=" + busca,
          {
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: self.props.session.token
            }
          }
        );

        if (!res.ok) {
          throw Error("Erro na cominicação com o servidor.");
        }

        res = await res.json();
        let totalNum = Helpers.totalPage(
          res.content.empresas.length,
          this.state.maxPerPage
        );
        this.setState({
          search: true,
          paginate: 0,
          change: false,
          totalPaginateNumber: totalNum === 0 ? 1 : totalNum,
          empresas: res.content.empresas
        });
      } catch (err) {
        alert(err.message);
      }
    } else {
      let res = await fetch(
        "https://api.bsfonline.com.br/trabalhadores/todos-por-entidade",
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: self.props.session.token
          },
          body: JSON.stringify({ ativos: self.state.onlyApp, busca: busca })
        }
      );

      res = await res.json();
      self.setState({
        trabalhadores: res.content,
        change: false,
        totalResult: res.total
      });
    }
  }

  changePage(pageName) {
    this.setState({
      page: pageName
    });
  }

  async onlyActive(checked) {
    let self = this;
    self.setState({
      onlyApp: checked
    });

    let busca = document.querySelector("#search-empresa");

    try {
      self.setState({
        change: true
      });
      let res = await fetch(
        "https://api.bsfonline.com.br/trabalhadores/todos-por-entidade",
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: self.props.session.token
          },
          body: JSON.stringify({ ativos: checked, busca: busca.value })
        }
      );

      res = await res.json();
      self.setState({
        trabalhadores: res.content,
        change: false,
        totalResult: res.total
      });
    } catch (e) {
      console.log(e);
    }
  }

  goTrabs(data, e) {
    let result = Object.assign({
      type: "entd",
      entidade: this.props.session.entidade,
      empresa: data,
      cnpj: this.props.session.cnpj,
      cnpj_empr: data.cnpj_empr,
      token: this.props.session.token
    });

    this.props.action.clearSelectTrabalhadores();

    this.setState({
      selectEmpresa: result,
      page: "trabalhadores"
    });
  }

  renderListEmpresas() {
    let arr = [];
    //TODO::BUG paginacao
    console.log(
      this.state.empresas,
      this.state.empresas.length < this.state.maxPerPage,
      this.state.maxPerPage
    );
    if (this.state.empresas.length < this.state.maxPerPage) {
      arr = Helpers.paginateArray(
        this.state.empresas,
        this.state.maxPerPage,
        this.state.paginate
      );
      console.log("arr", arr);
    } else {
      arr = Helpers.paginateArray(
        this.state.empresas,
        this.state.maxPerPage,
        this.state.paginate
      );
    }
    return arr.map((el, index) => {
      return (
        <li
          key={index}
          className="collection-item"
          onClick={e => this.goTrabs(el, e)}
        >

         <span className="title">{el.razao_empr}</span>
          <span>{el.cide_empr}</span> <br />
          <small>{el.cnpj_empr}</small>

        </li>
      );
    });
  }

  acronymName(data = "User Name") {
    return data
      .match(/\b\w/g)
      .join("")
      .substring(0, 2);
  }

  handleSelect(e, el, index) {
    //Ao clicar no trabalhar ativar checkobox
    let self = this;
    let element = document.querySelector("#filled-in-box" + index);
    if (element.checked) {
      element.checked = false;
      element.parentElement.classList.remove("active");
    } else {
      element.checked = true;
      element.parentElement.classList.add("active");
    }
    self.addTrabalhador(el, element);
  }

  addTrabalhador(trab, event) {
    if (event.checked) {
      let arr = this.state.listaSend.slice(0);
      arr.push(trab);
      console.log("array>", arr);
      this.props.action.insertSelectTrabalhadores(arr);
    } else {
      let arr = this.state.listaSend.slice(0);
      let index = arr.indexOf(trab);
      arr.splice(index, 1);
      this.props.action.insertSelectTrabalhadores(arr);
    }
  }

  limparPesquisa() {
    let totalNum = Helpers.totalPage(
      this.state.backupAll.length,
      this.state.maxPerPage
    );
    this.setState({
      search: false,
      paginate: 0,
      totalPaginateNumber: totalNum === 0 ? 1 : totalNum,
      empresas: this.state.backupAll
    });
  }

  typeSearch(e) {
    if (e.target.checked) {
      this.setState({
        buscaEmpresa: true
      });
    } else {
      this.setState({
        buscaEmpresa: false
      });
    }
  }

  render() {
    let empresasRender = null;
    let TrabalhadoresLista = [];
    let page = null;
    if (this.state.empresas.length === 0 && this.state.search === false) {
      empresasRender = <div className="no-empresas" />;
    } else if (this.state.empresas.length === 0 && this.state.search === true) {
      empresasRender = (
        <div className="no-empresas">
          <h2>Nada encontrado</h2>
        </div>
      );
    } else {
      empresasRender = this.renderListEmpresas();
    }

    TrabalhadoresLista = this.state.trabalhadores.sort((a, b) => {
      if (a.nome_tbldr.toUpperCase() < b.nome_tbldr.toUpperCase()) return -1;
      if (a.nome_tbldr.toUpperCase() > b.nome_tbldr.toUpperCase()) return 1;
      return 0;
    });

    TrabalhadoresLista = TrabalhadoresLista.map((el, index) => {
      let picture = (
        <i className="fa  circle" aria-hidden="true">
          {" "}
          {this.acronymName(el.nome_tbldr)}{" "}
        </i>
      );
      if (el.avatar_tbldr && el.avatar_tbldr !== "") {
        picture = <img src={el.avatar_tbldr} alt="" className="circle" />;
      }

      el.originalIndex = index;

      let retorno = (
        <li
          key={index}
          className="collection-item avatar"
          data-info={el.nome_tbldr + " " + el.cpf_tbldr}
          onClick={e => this.handleSelect(e, el, index)}
        >
          <input
            type="checkbox"
            className="filled-in trab-box is-trab"
            id={"filled-in-box" + index}
            value={el.cpf_tbldr}
            data={el.nome_tbldr}
            json={JSON.stringify(el)}
            onChange=""
          />
          <label htmlFor={"filled-in-box" + index} />
          {picture}
          {/* <span className="poiter-online active"></span> */}
          <span className="title">
            {el.nome_tbldr}
            <p>
              <small>
                {el.fncao_tbldr} -{" "}
                {el.sexo_tbldr === "M" ? "MASCULINO" : "FEMININO"}
              </small>
            </p>
          </span>

          {el.tb_pltfm_infos !== "0" ? (
            <i
              style={{ color: "#3ab5ef", fontSize: 35 }}
              className="fa fa-mobile"
              aria-hidden="true"
            />
          ) : (
            ""
          )}
        </li>
      );

      return retorno;
    });

    const { Header, Content, Footer, Sider } = Layout;

    if (this.state.page === "empresa") {
      page = (
        <Layout>
          <Sider width={593}>
            <div className="empresa-busca">
              <div className="card hoverable">
                <div className="busca-empresa">
                  <div className="card-panel">
                    <form action="" onSubmit={this.busca}>
                      <div className="row">
                        <div className="frase-busca-empresa">
                          Para criar uma nova mensagem, você deve <br />
                          <b>
                            <u>selecionar</u>
                          </b>{" "}
                          uma empresa ou trabalhador.
                        </div>
                        <div
                          className="col s12"
                          style={{ position: "relative" }}
                        >
                          <Icon type="search" style={{ left: "33px" }} />
                          <input
                            type="text"
                            name="busca"
                            ref="searchEmpresa"
                            placeholder={
                              this.state.buscaEmpresa === true
                                ? "Digite o nome da empresa ou CNPJ"
                                : "Digite o nome ou CPF do trabalhador"
                            }
                            id="search-empresa"
                            style={{ margin: "0px" }}
                            required
                          />
                        </div>

                        <div className="col s3">
                          {/*<p className="right-align">
                                            <button className="btn waves-effect waves-light" type='submit'>PESQUISAR</button>
                                            <button className="btn waves-effect waves-light" onClick={this.limparPesquisa} disabled={!this.state.search}>LIMPAR PESQUISA</button>
                                        </p>*/}
                        </div>
                      </div>
                    </form>

                    <div style={{ padding: "0px 15px", marginBottom: "15px" }}>
                      <Row>
                        <Col span={12}>
                          <Checkbox onChange={this.typeSearch}>
                            Por empresa/CNPJ
                          </Checkbox>
                        </Col>
                        <Col span={12}>
                          {/* <Checkbox onChange={this.onlyActive}>Trabalhadores com aplicativo instalado</Checkbox> */}
                          <div className="isAPP">
                            <Switch
                              defaultChecked
                              onChange={this.onlyActive}
                              size="small"
                            />
                            Trabalhadores com aplicativo
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="col s6" />

                    <div className="col s12">
                      <Scrollbars className="criar-msg-scroll">
                        <div className="col s24">
                          {this.state.change === true && (
                            <div style={{ padding: "24px" }}>
                              <Skeleton avatar paragraph={{ rows: 2 }} active />
                              <Skeleton avatar paragraph={{ rows: 2 }} active />
                              <Skeleton avatar paragraph={{ rows: 2 }} active />
                              <Skeleton avatar paragraph={{ rows: 2 }} active />
                            </div>
                          )}
                          {this.state.change === false &&
                            this.state.buscaEmpresa === false && (
                              <ul
                                className="collection custom-list-trabalhadores"
                                style={{ boxShadow: "none" }}
                              >
                                {TrabalhadoresLista}
                              </ul>
                            )}

                          {this.state.buscaEmpresa === true && (
                            <ul className="lista-empresas">{empresasRender}</ul>
                          )}
                        </div>
                      </Scrollbars>

                      {this.state.buscaEmpresa === false && (
                        <div className="pagination-trabalhadores">
                          <Pagination
                            defaultCurrent={1}
                            pageSize={30}
                            total={this.state.totalResult}
                            onChange={this.onChange}
                            showTotal={total =>
                              `Total ${this.state.totalResult} trabalhadores`
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  {/*<Paginate
                        totalPaginateNumber={this.state.totalPaginateNumber}
                        paginate={this.state.paginate}
                        returnPage={page => this.updatePaginate(page)}/> */}
                </div>
                {/*<TrabalhadorEntidade/>*/}
              </div>
            </div>
          </Sider>

          <Content span={18} style={{ position: "relative" }}>
            <div className="cp-send" style={{ padding: 0 }}>
              <div id="box-send-new">
                {this.props.url != "/lista-de-envio" ? (
                  <TrabalhadorEntidade />
                ) : (
                  ""
                )}
              </div>
            </div>
          </Content>
        </Layout>
      );
    } else {
      page = (
        <Trabalhadores
          ref={instance => (this.trabalhadoresRef = instance)}
          session={this.state.selectEmpresa}
          entidade={true}
          changePage={this.changePage}
          url={this.props.url}
        />
      );
    }

    return page;
  }
}

function mapStateProps(state) {
  return {
    empresa: state.empresas,
    selectTrabs: state.trabsSelect
  };
}

function mapDispatchProps(dispatch) {
  return {
    action: bindActionCreators(
      Object.assign(EmpresaActions, TrabalhadoresSelectActions),
      dispatch
    )
  };
}

export default connect(
  mapStateProps,
  mapDispatchProps
)(SelectEmpresa);
