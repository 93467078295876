import React from "react";
import { Menu, Icon } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Layout, Row, Col, Button, Dropdown, Modal, Tabs } from "antd";
import $ from "jquery";
import Base64 from "base-64";
import yt_play from "../yt_play.svg";
import Tabsnavigation from './MenuNavigation'


import Cookie from "js-cookie";

//actions
import * as TrabalhadoresActions from "../actions/trabalhadoresAction";
import * as TrabalhadoresSelectActions from "../actions/trabsSelectActions.js";
import * as SelectState from "../actions/SelectStateAction";

import avatar from "../_assets/img/avatar-header.png";

import YouTube from "react-youtube";
import history from "../_config/history";
import { compose } from "recompose";
const siteTutorial =
  "https://www.beneficiosocial.com.br/api/getvideotutorial?sessao=empresa&menu=PARA%20A%20EMPRESA&titulo=BENEFÍCIO%20CONECTA%20EMPRESA";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.changeStateRedux = this.changeStateRedux.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onlyEmp = this.onlyEmp.bind(this);
    this.menuHeader = this.menuHeader.bind(this);
    this.showTabsNavigation = this.showTabsNavigation.bind(this)

    this.youtubeInstance = null;
    this.state = {
      session: JSON.parse(Base64.decode(Cookie.get("conecta_session"))),
      typeHeader: 1,
      modalTutorialVisible: false,
      tutorialURL: null
    };


  }

  changeStateRedux(e) {
    $(".filled-in.trab-box").prop("checked", false);
    if (this.props.toogleSelectTrabalhadore) {
      this.props.action.toogleState();
    }
    this.props.action.clearSelectTrabalhadores();
  }

  goBack(e) {
    window.location.href = "https://www.beneficiosocial.com.br/empresa";
  }

  onlyEmp(iden) {
    if (iden === "empr") {
      return (
        <Menu.Item key="criar-grupo">
            <Link to="/criar-grupo" onClick={this.changeStateRedux}>
                <Icon type="usergroup-add" />
                MENSAGENS EM GRUPO
            </Link>
        </Menu.Item>
      );
    }
  }

  menuHeader() {
    return (
      <Menu
          theme="dark"
          mode={this.state.typeHeader === 1 ? "horizontal" : ""}
          defaultSelectedKeys={["2"]}
          style={{ lineHeight: "60px", fontSize: "13px" }}
      >
          <Menu.Item key="listMessage">
              <Link to="/chat" onClick={this.changeStateRedux}>
                  <Icon type="inbox" /> MENSAGENS
              </Link>
          </Menu.Item>

          <Menu.Item key="create-mesage">
              <Link to="/criar-mensagem" onClick={this.changeStateRedux}>
                  <Icon type="wechat" /> CRIAR MENSAGENS INDIVIDUAIS
              </Link>
          </Menu.Item>

          <Menu.Item key="lista">
              <Link to="/lista-de-envio" onClick={this.changeStateRedux}>
                  <Icon type="bars" /> LISTA DE ENVIO
              </Link>
          </Menu.Item>

          {this.onlyEmp(this.state.session.type)}
      </Menu>
    );
  }

  showTabsNavigation(){
    const TabPane = Tabs.TabPane;

    return(
      <div className="tabs-menu-header">
          <button onClick={()=> this.props.history.push('/chat')}><Icon type="mail" /><br/>MENSAGENS</button>
          <button onClick={()=> this.props.history.push('/criar-mensagem')}>CRIAR <br/> MENSAGENS</button>
          <button onClick={()=> this.props.history.push('/lista-de-envio')}>LISTA DE ENVIO</button>

      </div>
    );
  }

  /**
   * MODAL STRUCTURE
   */
  showModal = () => {
    this.setState({
      modalTutorialVisible: true
    });
  };
  modalCancel = (e) => {
    if(this.youtubeInstance){
        this.youtubeInstance.internalPlayer.pauseVideo()
    }

    this.setState({
      modalTutorialVisible: false
    });
  };
  async componentDidMount() {
    let self = this;
    setTimeout(async () => {
      try {
        let result = await fetch(siteTutorial);
        result = await result.json();
        self.setState({
          tutorialURL: result.content
        });
      } catch (error) {
        console.log(error);
        // this.setState({
        //     tutorialURL: 'KG_xUX2nSC4'
        // })
      }
    }, 1500);
  }

  componentWillMount() {
    let widthScreen = $(window).width();

    if (widthScreen < 1250) {
      this.setState({
        typeHeader: 2
      });
    }
  }

  render() {

    let youtube = null;
    let btlYoutube = null;
    let { operador, empresa, entidade } = this.state.session;
    if (this.state.tutorialURL) {
      btlYoutube = (
        <div onClick={this.showModal} className="video-tutorial animated tada">
            <img className="img" src={yt_play} alt="" />
            <div className="text">
                Vídeo
                <br />
                Tutorial
            </div>
        </div>
      );
      youtube = (
        <YouTube
            ref={instance => (this.youtubeInstance = instance)}
            videoId={this.state.tutorialURL}
            id="youtube"
            opts={{
            height: "470",
            width: "90%",
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 0
            }
          }}
      />
   );
    }
    const { Header, Content, Footer } = Layout;
    return (
      <div>
          <Header>
              {/* coluna info user */}
              <Col span={16} offset={4}>
                  <Row type="flex" justify="space-around" align="middle" className="middle-header">
                      <Col span={8}>
                          <div className="user-info-content">
                              <img
                                  src={avatar}
                                  alt="avatar"
                                  style={{ verticalAlign: "middle" }}
                              />
                              <div
                                  style={{ verticalAlign: "middle", display: "inline-block" }}
                              >
                                  <span>
                      <b>

                          { empresa!= null ? empresa.razao_empr.substr(0,20) : entidade.razao_entd.substr(0, 20)}</b>
                  </span>
                  <span>{ operador ? operador.nome_empr_oprdr.substr(0, 20) : ''}</span>
              </div>
          </div>
      </Col>

      {/* Coluna logo centro */}
      <Col span={8}>
          <div className="logo">
                <center>
                    <img src="/images/logo.png" />
                </center>
            </div>
            </Col>

            <Col span={8}>
                {btlYoutube}
              <Modal
                  title="Video Tutorial"
                  visible={this.state.modalTutorialVisible}
                  onCancel={this.modalCancel}
                  footer={null}
                  width={this.state.typeHeader === 1 ? "60%" : "90%"}
              >
                  <center>{youtube}</center>
              </Modal>
              <div className="btn-back-header">
                  <Button type="primary" onClick={this.goBack}>
                      Voltar BSF
                  </Button>
              </div>
          </Col>
      </Row>

  </Col>


          {/* <Col span={this.state.typeHeader === 1 ? 17 : 9}>
            {this.state.typeHeader === 1 ? (
              this.menuHeader()
            ) : (
              <Dropdown overlay={this.menuHeader()}>
                <Button style={{ marginLeft: 50 }} className="ButtonHeader">
                  <Icon type="bars" />
                </Button>
              </Dropdown>
            )}
          </Col> */}


          {/* <Tabsnavigation /> */}


      </Header>
  </div>
    );
  }
}

function mapStateProps(state) {
  return {
    trabalhadores: state.trabalhadores,
    selectTrabs: state.trabsSelect,
    toogleSelectTrabalhadore: state.toogleSelectTrabalhadore
  };
}

function mapDispatchProps(dispatch) {
  return {
    action: bindActionCreators(
      Object.assign(
        TrabalhadoresActions,
        TrabalhadoresSelectActions,
        SelectState
      ),
      dispatch
    )
  };
}

// export default connect(
//   mapStateProps,
//   mapDispatchProps
// )(Header);


export default compose(
  withRouter,
  connect(
    mapStateProps,
    mapDispatchProps
  )
)(Header)
