import socket from "socket.io-client";

//const url = 'http://127.0.0.1:8080'
const url = "https://api.bsfonline.com.br";

const conectMessages = socket(url + "/messages");
const conectChannels = socket(url + "/channels");
const conectListMessages = socket(url + "/list-messages");

/*************** CHANNELS ****************/
/**
 * Emit para pegar os primeiros registros
 */
export const getChannels = cnpj => {
  conectChannels.emit("channel-get", {
    type: "get",
    cnpj: cnpj
  });
};

export const getChannelsList = cnpj => {
  console.log("Emit Lista", cnpj);
  conectChannels.emit("channel-get", {
    type: "get",
    cnpj: cnpj,
    list: true
  });
};

export const getChannelsGroups = cnpj => {
  conectChannels.emit("channel-get", {
    type: "get",
    cnpj: cnpj,
    group: true
  });
};

export const setChannel = data => {
  conectChannels.emit("channel-set", data);
  console.log(data)
};

/**
 * Metodo pre definido apra
 */
export const listensChannels = (cnpj, callback) => {
  conectChannels.on(`channel-update-${cnpj}`, function(res) {
    callback(res);
  });
};

/************* FIM CHANNELS ***************/

/****************** MESSAGES *****************/
export const getMessages = (id, send) => {
  conectMessages.emit("messages-get", send);
};

export const setMessages = send => {
  conectMessages.emit("messages-set", send);
};

export const listensMessages = (
  { _id, cnpj },
  callback_update,
  callback_get
) => {
  conectMessages.on(`messages-update-${_id}`, function(res) {
    callback_update(res);
  });

  conectMessages.on(`messages-get-${cnpj}-${_id}`, function(res) {
    callback_get(res);
  });
};
/*************** FIM MESSAGES ****************/

/****************** LISTA DE EMNSAGENS *****************/
export const getListMessages = send => {
  conectMessages.emit("list-messages-get", send);
};

export const setListMessages = send => {
  conectMessages.emit("list-messages-set", send);
};

export const listensListMessages = (
  { cnpj },
  callback_update,
  callback_get
) => {
  conectMessages.on(`list-messages-update-${cnpj}`, function(res) {
    callback_update(res);
  });

  conectMessages.on(`list-messages-get-${cnpj}`, function(res) {
    callback_get(res);
  });
};
/*************** FIM LISTA DE EMNSAGENS ****************/
