export const insertSelectTrabalhadores = (trab) =>{
    return {
        type: 'INSERT_TRABALHADORES_SELECT',
        data: trab
    }
}

export const addSelectTrabalhador = (trab) =>{
    return {
        type: 'ADD_TRABALHADORES_SELECT',
        data: trab
    }
}
export const removeSelectTrabalhador = (trab) => {
    return {
        type: 'REMOVE_TRABALHADORES_SELECT',
        data: trab
    }
}
export const clearSelectTrabalhadores = () =>{
    return {
        type: 'CLEAR_TRABALHADORES_SELECT',
    }
}
