import React from 'react'
import * as Helpers from '../helpers/Helpers.js'
import $ from 'jquery'


class Paginate extends React.Component {
    //gera os numero das paginas e organização de qual ativo e não
    numPage(){
        let paginas = []

        for(let i = 1; i <= this.props.totalPaginateNumber; i++){
            paginas.push(i)
        }

        paginas = paginas.map((value, index) => {
            let clase =  ""

            if(this.props.paginate === value){
                clase = "active"
            }
            return (
                <li key={value} className={clase}>
                    <a style={{cursor: "pointer"}} onClick={e =>  this.updatePaginate(value)}>{value}</a>
                </li>
            )
        })

        if(this.props.paginate > 4){
            $('.first').show()
        }else{
            $('.first').hide()
        }

        if(this.props.paginate > this.props.totalPaginateNumber - 4){
            $('.last').hide()
        }else{
            $('.last').show()
        }

        return Helpers.listaNumPages(this.props.paginate || 1, this.props.totalPaginateNumber || 1, paginas)
    }

    //retorna a pagina selecionada para o parent
    updatePaginate(value){
        this.props.returnPage(value)
    }

    render(){
        let content = null

        if(this.props.totalPaginateNumber === 1){
            return <div></div>
        }else{
            return <div className="paginate">
                <button onClick={e => {
                    if(this.props.paginate > 1)
                        this.updatePaginate(this.props.paginate - 1)
                    }
                }>Anterior</button>
                <button onClick={e => {
                    if(this.props.paginate < this.props.totalPaginateNumber )
                        this.updatePaginate(this.props.paginate + 1)
                    }
                }>Próximo</button>
                <ul className="listNumbers">
                    <li className="first">
                        <a style={{cursor: "pointer"}} onClick={e => this.updatePaginate(1)}>1</a>
                    </li>
                    <li className="first">
                        ...
                    </li>
                    {this.numPage()}
                    <li className="last">
                        ...
                    </li>
                    <li className="last">
                        <a style={{cursor: "pointer"}} onClick={e => this.updatePaginate(this.props.totalPaginateNumber)}>{this.props.totalPaginateNumber}</a>
                    </li>
                </ul>
            </div>
        }
    }
}

export default Paginate
