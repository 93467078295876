import React from 'react'
import Base64 from 'base-64'
import Cookie from 'js-cookie'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Layout, Menu, Icon, Button, Radio,  Switch, Row, Col, Spin, Alert } from 'antd';
import CreateMessage from './CreateMessage'
import $ from 'jquery'

//ACTIONS
import * as TrabalhadoresActions from '../actions/trabalhadoresAction'
import * as TrabalhadoresSelectActions from '../actions/trabsSelectActions.js'
import * as SelectStateActions from '../actions/SelectStateAction.js'

class EntidadeTrabalhadorBusca extends React.Component {
    constructor(props) {
        super(props)

        let session = JSON.parse(Base64.decode(Cookie.get('conecta_session')))

        this.state= {
			session: session,
			listaSend: [],
			trabalhadores: [],
			loading: false,
			iconLoading: false,
			search: false
        }
        this.onChange = this.onChange.bind(this)
        this.addTrabalhador = this.addTrabalhador.bind(this)

    }

    renderListaTrbalhadores(){

    	let lista = this.state.trabalhadores

    	if(lista.length === 0 && this.state.search === false){

    	}else if(lista.length === 0 && this.state.search === true){
    		return <div align='center'>Nenhum trabalhador encontrado.</div>
    	} else {
    		return lista.map(el => {
	    		return <li>
	    			<Row>
	    			<Col span={5}>{el.nome_tbldr}</Col>
	    			<Col span={14}> <b>EMPRESA: </b>{el.razao_empr}</Col>
	    			<Col span={5}> <button className="btn btn-send"
	    			onClick={e =>   this.addTrabalhador(el,e)}>ENVIAR MENSAGEM</button></Col>
	    			</Row>
	    		</li>
    		})
    	}


    }

    addTrabalhador(trab, event){


            let arr = this.state.listaSend.slice(0)
            arr.push(trab)
            console.log('array>', arr);
            this.props.action.insertSelectTrabalhadores(arr)

         let botao =  document.getElementsByClassName('btn-sendcollection-item')

       		setTimeout(()=>{
       			$('.btn-sendcollection-item').trigger('click')
       		}, 2000)
    }

    async onChange(e){

    	let self = this
    	let cnpjEntidade = self.state.session
    	self.setState({
    		loading: true
    	})

    	let inputBusca = document.getElementById('inpurBuscaTrabalhador').value
    	let busca = '?search='+ inputBusca.toUpperCase()
    	try{
    		let res = await fetch('https://api.bsfonline.com.br/trabalhadores/busca/empresa' + busca, {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.state.session.token
                }
            })

            if(!res.ok){
                throw Error("Erro na comunicação com o servidor.")
            }

            res = await res.json()

            let resultadoCompleto = res.content



            let uniqueObjetc =  resultadoCompleto.filter((obj, pos, arr) => {
									return arr.map(mapObj => mapObj['nome_tbldr']).indexOf(obj['nome_tbldr']) === pos;
    							});

         	self.setState({
         		trabalhadores: uniqueObjetc,
         		loading: false,
         		search: true
         	})
    	}catch(e){
    		//alert(e.message)
    		console.log(e.message)
    	}
    }

	render() {

		return (

			<div className='buscaTrabalhadorEntidade'>

				 <CreateMessage trabalhadores={this.state.listaSend} session={this.state.session} url={this.props.url} />
                 {/*<Spin tip="Carregando..." spinning={this.state.loading}>
					<Col span={19}>
						<Icon type= "search" />
						<input type="text" name="busca" id="inpurBuscaTrabalhador" placeholder="Buscar Trabalhador"/>
						<Col span={24}>

							<div style={{ overflow: 'auto', height: '200px'}}>
								<ul className='listaTrabalhadorbusca'>
									{this.renderListaTrbalhadores()}
								</ul>
							</div>
						</Col>
					</Col>
					<Col span={5}>
						<button
						className='btn'
						onClick={this.onChange}
						type="primary"
						loading={this.state.loading}
						>PESQUISAR</button> <button className="btn  waves-light">LIMPAR PESQUISA</button>
					</Col>
                  </Spin>*/}

			</div>
		)
	}
}

function mapStateProps(state){
    return {
        trabalhadores: state.trabalhadores,
        selectTrabs: state.trabsSelect,
        toogleSelectTrabalhadore: state.toogleSelectTrabalhadore
    }
}

function mapDispatchProps(dispatch){
    return {
        action: bindActionCreators(Object.assign(TrabalhadoresActions, TrabalhadoresSelectActions, SelectStateActions), dispatch)
    }
}

export default connect(mapStateProps,mapDispatchProps)(EntidadeTrabalhadorBusca)
