import React from "react";
import ReactDom from "react-dom";
import ConectaChat from "./pages/ConectaChat";
import GrupoSend from "./Components/GruposSend";
import Perfil from "./pages/Perfil";
import Grupo from "./pages/Grupo";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import Store from "./store";
import { Layout, Col } from "antd";
import Header from "./Components/Header";
import Navigation from './Components/MenuNavigation'
import Cookies from "js-cookie";
import base64 from "base-64";
import history from "./_config/history";

import "antd/dist/antd.css";
import "./LayouSkype.css";
import style from "./styles/css/style.css";

import materialize from "./materialize-src/css/materialize.css";
//import materialize from '../materialize-src/css/materialize.css'
import fontawesome from "../node_modules/font-awesome/css/font-awesome.css";

const StoreInstance = Store();
const { Footer, Content, Sider } = Layout;

ReactDom.render(
  <Provider store={StoreInstance}>
    <Router history={history}>
      <Layout className="layout">
        <Header />
        <Col span={24} className="navigation-top">
          <Navigation/>
        </Col>  
        <Col span={16} offset={4} className="conteudo-pages">  
           
          <Content>           
            <Switch>
            <Route exact path="/" component={ConectaChat} />
            <Route exact path="/chat" component={ConectaChat} />
            <Route exact path="/lista-de-envio" component={GrupoSend} />
            <Route exact path="/criar-mensagem" component={Perfil} />
            <Route exact path="/criar-grupo" component={Grupo} />
            </Switch>
          </Content>
        </Col>
      </Layout>
    </Router>
  </Provider>,
  document.getElementById("root")
);
