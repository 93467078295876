import React from 'react'
import Base64 from 'base-64'

import Trabalhadores from '../Components/Trabalhadores'
import Cookie from 'js-cookie'
import SelectEmpresa from '../Components/SelectEmpresa.js'
//import GrupoSend from '../Components/GruposSend.js'
import GrupoChat from '../pages/GroupChat'
import * as Firebase from '../helpers/firebase'

//actions


import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

class Grupo extends React.Component {
    constructor(props) {
        super(props)
        this.trabalhadoresRef = null

        this.state = {
            session: JSON.parse(Base64.decode(window.localStorage.getItem("conecta_session"))),
            createMessage: true,
            listTrabalhadores: [],
             isOpened: false,
        }

         this.toggleBox = this.toggleBox.bind(this);
    }

    componentDidMount() {
        let cnpj = this.state.session.cnpj

        Firebase.addPushToken(cnpj, this.state.session.token)
    }

  toggleBox() {

    const { isOpened } = this.state;
    this.setState({    
      isOpened: !isOpened,
    });
  }
    render() {

        let perfil = null
         const { isOpened } = this.state;
        if (this.state.session.type === 'empr') {
            perfil = <Trabalhadores ref={instance => this.trabalhadoresRef = instance} session={this.state.session} url={this.props.location.pathname}/>
        } else {
            perfil = <SelectEmpresa session={this.state.session}  />
        }

        return (<div className="content-page">
           
            <div id="seleciona-trabalhadores" className="col s12">
                {this.props.page  &&  perfil }
            </div>

            {!this.props.page && (<GrupoChat url={this.props.location.pathname}/>)}

        </div>)
    }
}

function mapStateProps(state){
    return {
        page: state.navigation
    }
}

export default connect(mapStateProps, null)(Grupo)





