import React from 'react'
import * as Firebase from '../helpers/firebase.js'
import CreateMessage from '../Components/CreateMessage.js'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Base64 from 'base-64'
import Cookie from 'js-cookie'
import { Layout, Menu, Icon, Button, Radio, Modal  } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import $ from 'jquery'

import Trabalhadores from '../Components/Trabalhadores'
import SelectEmpresa from '../Components/SelectEmpresa.js'
import CreateList from '../Components/CreateList'
import CreateGroups from '../Components/CreateGroups'
import * as Socket from '../helpers/socket'
//ACTIONS
import * as TrabalhadoresSelectActions from '../actions/trabsSelectActions.js'

class GrupoSend extends React.Component {
    constructor(props){
        super(props)
        this.trabalhadoresRef = null
        this.state = {
            session:  JSON.parse(Base64.decode(Cookie.get('conecta_session'))),
            listaSend: [],
            listGroups: [],
            isOpened: false,
        }
        this.listensChannels = Socket.listensChannels.bind(this)
        this.addGroupToSend = this.addGroupToSend.bind(this)
        this.busca = this.busca.bind(this)
        this.viewWorkers = this.viewWorkers.bind(this)
        this.toggleBox = this.toggleBox.bind(this)
        this.handleListSelect =this.handleListSelect.bind(this)
        this.receiveData = this.receiveData.bind(this)
        this.props.action.clearSelectTrabalhadores()


    }
    componentWillReceiveProps(props, oldProps){
        this.setState({
            listaSend: props.selectTrabs
        })
    }

    receiveData(value){
        this.setState({
            listGroups: value.data
        })
    }

    componentDidMount(){
        Socket.getChannelsList(this.state.session.cnpj)
        this.listensChannels(this.state.session.cnpj, this.receiveData)
    }

    /*componentWillMount(){
        let self = this


        let refs = Firebase.getGroupList(this.state.session.cnpj)

        refs.on("value", (snap) => {
            let value = snap.val()

            if(value){
                let obj = Object.keys(value).map((key) => {
                    value.key = key
                    return value[key]
                })
                if(obj.length){
                    this.setState({
                        listGroups: obj
                    })
                }
            }
        })
    }*/

    busca(e){
        let valor = e.target.value
        let regex = RegExp(valor, "ig")

        let resultado = this.state.data.empresa.tb_tbldrs.filter((el) => {
            if(regex.exec(el.nome_tbldr + " " + el.cpf_tbldr)){
                return true
            }
        })
        this.setState({
            trabalhadores: resultado
        })
    }

    viewWorkers(title,list){
        const content = list;
        const modal  =   Modal.info({
            title: title,
            content: content
        })

        return modal

    } 

    handleListSelect(index, trab){
       let elem = document.querySelector('#filled-in-box-g-'+index) 
       console.log(elem.parentElement)
       if(elem.checked){
            elem.checked = false
            elem.parentElement.classList.remove('active')

       } else {
           elem.checked =  true
            elem.parentElement.classList.add('active')
       }
       this.addGroupToSend(trab, elem)
    }

    addGroupToSend(element, e){
        let data = Object.assign(this.state.listaSend)
            if(e.checked){
                let lista = element.users

                data = data.concat(lista)
                console.log(data)
                this.props.action.insertSelectTrabalhadores(data)
                this.setState({
                    listaSend: data
            })

        }else{
            let lista = element.users
            lista.map((el, index) => {
                data.map((e,i) =>{
                    console.log(e,i)
                    if(el._id === e._id){
                        data.splice(i,1)
                    }
                })
                //data.splice(element.users[keyi], 1)
            })
            this.props.action.insertSelectTrabalhadores(data)

            this.setState({
                listaSend: data
            })
        }
    }
    // Sele
   toggleBox() {

    const { isOpened } = this.state;
    this.setState({    
      isOpened: !isOpened,
    });
    this.props.action.clearSelectTrabalhadores()  //limpando selecionados
  }

    render(){
        const { Header, Footer, Sider, Content } = Layout;
        let listGroupsRender = null
        let listaTralhadores = this.state.listGroups
        if(listaTralhadores != null &&  listaTralhadores.length){
            listGroupsRender = listaTralhadores.map((el, index) => {
                let trabalhadoresSub = el.users.map((elSub, indexSub) => {
                    if (elSub.name){
                        return <div key={indexSub} className="trab-sub">{elSub.name} - {elSub.cpf}</div>
                    }
                })

                let retorno = <li 
                    key={index}
                    id={'lista-' + index} 
                    className="collection-item avatar"
                    data-info={el.nome}
                    onClick={()=> this.handleListSelect(index, el)}
                >
                    <i className="fa circle" aria-hidden="true">
                        <Icon type="team" />
                    </i>
                    <p>
                        <span className="title">
                            { el.subject } <br/>
                        </span>

                        <span className="description">{ el.description }</span>
                    </p>
                    <input type="checkbox" 
                        className="filled-in trab-box" 
                        id={"filled-in-box-g-" + index } 
                        value={ el.cpf_tbldr } 
                        data={ el.nome_tbldr } 
                        json={JSON.stringify(el)} 
                        onChange={ e => this.addGroupToSend(el, e)}
                    />
                    <label htmlFor={ "filled-in-box-g-" +  index }></label>
                    <div className="sub-trabalhadores">
                        <Button 
                            icon="user" 
                            onClick={() => { this.viewWorkers(el.name,trabalhadoresSub) }}> 
                            {trabalhadoresSub.length} 
                        </Button>
                    </div>
                </li>
                return retorno
            })
        }

         let perfil = null
         const { isOpened } = this.state;
        if (this.state.session.type === 'empr') {
            perfil = <Trabalhadores ref={instance => this.trabalhadoresRef = instance} session={this.state.session} url={this.props.location.pathname}/>
        } else {
            perfil = <SelectEmpresa session={this.state.session}  url={this.props.location.pathname}/>
        }

        return (<Layout>
            <Sider  className="col-users">
                <Scrollbars style={{ height:'calc(100% - 70px)' }}>

                    { isOpened && (perfil) }
                    {!isOpened && (
                            <ul className="collection send-group">
                                { listGroupsRender }
                            </ul>
                        )}

                    </Scrollbars>
                    <div  className="btn-send-list">
                        <Button 
                            type="primary" 
                            id= "lista-grupo"
                            onClick={() => this.toggleBox()}
                        >
                            { !isOpened ? 'CRIAR LISTA' : 'VOLTAR' }
                        </Button>
                    </div>    
                </Sider>
                <Content  span={18} style={{ position: "relative" }} > 
                    <div className='grupos'>

                        {/* <div id="seleciona-trabalhadores" className="pagina-grupo col s12">
                <div align="right">
                    <button class="btn" onClick={this.toggleBox}>{isOpened === true ? 'Fechar': 'Nova Lista'}</button>      
                </div>  
                <div className="createList">
                    {isOpened  && ( perfil )}
                </div> */}
                {!isOpened && (

                        <CreateMessage trabalhadores={this.state.listaSend} session={this.state.session} url={this.props.location.pathname}/>
                        )}

                        {isOpened && (
                            <CreateGroups  session={this.state.session} url={this.props.url}/>
                        )}

                        {/* <div className="busca-trabalhador">
                    <div className="card-panel">
                        <input type="text" placeholder="Buscar Trabalhador" id="search-trabalhadores" onChange={this.busca}/>
                    </div>
                </div> */}
            </div>
        </Content>
    </Layout>)
    }
}

function mapStateProps(state){
    return {
        selectTrabs: state.trabsSelect
    }
}

function mapDispatchProps(dispatch){
    return {
        action: bindActionCreators(Object.assign(TrabalhadoresSelectActions), dispatch)
    }
}

export default connect(mapStateProps, mapDispatchProps)(GrupoSend)
