// flow
import React from "react";
import ReactDOM from "react-dom";
import * as Firebase from "../helpers/firebase";
import Base64 from "base-64";
import Cookie from "js-cookie";
import moment from "moment";
import "moment/locale/pt-br";
import $ from "jquery";
import uuidV4 from "uuid/v4";
import { Layout, Row, Col, Button, Input, Icon } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "underscore";
import SpringScrollbars from "./Scroolbars";
import anexo from '../_assets/img/attachment.png'
import * as Socket from '../helpers/socket'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
//IMG

import ButtonSend from "../_assets/img/right-arrow.png"
import IconGroup from '../_assets/img/group-profile-users.png'

//actions
import * as navigationActions from '../actions/navigationActions'

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.refFirebase = null;
    this.state = {
      lista: [],
      dados: [],
      active: {},
      lastActive: null,
      hiddenOp: 0,
      content: (
        <center>
          <img
            src="/images/mstile-310x310.png"
            alt=""
            style={{opacity: 0.2, marginTop: 50 }}
            className="init-logo"
          />
        </center>
      ),
      session: JSON.parse(Base64.decode(window.localStorage.getItem("conecta_session"))),
      scrollTop: 0,
      dadosLista: []
    };
    
    // SocketIO method
    this.listensChannels = Socket.listensChannels.bind(this)
    this.listensMessages = Socket.listensMessages.bind(this)
    this.receiveData = this.receiveData.bind(this)
    this.receiveMessages = this.receiveMessages.bind(this)
    this.getMessages = this.getMessages.bind(this)

    this.renderStateList = this.renderStateList.bind(this);
    this.handlerChat = this.handlerChat.bind(this);
    this.contentChat = this.contentChat.bind(this);
    this.acronymName = this.acronymName.bind(this);
    this.handleScrollFrame = this.handleScrollFrame.bind(this);
    this.busca = this.busca.bind(this);
    this.backRenderList = this.backRenderList.bind(this);


    
  }

  handlerChat(data, updatedAt) {


    if(data && data.length){
      //order from date
        data = data.sort((a,b) => new Date(a.updatedAt) - new Date(b.updatedAt))

      let conversa = []
      let lastDate = null;

      //render model
      conversa = data.map((el, index) => {
        let classe = el.user._id === 0 ? "me" : "you";
        let date = moment(el.createdAt);
        let divisorDate = null;
        let printViasualizado = null;
        let nameOperador = null;

        if (
          lastDate === null ||
          lastDate.format("DD/MM/YYYY") !== date.format("DD/MM/YYYY")
        ) {
          lastDate = date;
          divisorDate = <div className="message-date">{date.format("LL")}</div>;
        }

        //confere se a mensagem foi viasualizado
        if (conversa.length === index + 1 && el.user._id === 0 &&  el.seen.cpf) {
          printViasualizado = (<span className="visualizado">
              Visualizado <small>{moment(el.updatedAt).format("DD/MM/YYYY h:mm:ss a")}</small>
            </span>)

        }
        if (conversa.length === index + 1 && el.user._id === 0 && el.seen.cpf === false
        ) {
          printViasualizado = (
            <span className="visualizado">
              Enviado -{" "}
              <small>
                {moment(el.updatedAt).format("DD/MM/YYYY h:mm:ss a")}
              </small>
            </span>
          );
        }

        if (!el.operator  && classe === "me") {
          nameOperador = <h5 className="nameOperador">{el.name}</h5>;
        }

        if (el.user && classe === "you") {
          nameOperador = <h5 className="nameOperador">{el.name}</h5>;
        }

        return (
          <div key={index}>
            {divisorDate}
            <div className={"message-content " + classe}>
              <div style={{marginLeft: '30px', marginRight: "30px"}}>{nameOperador}</div>
              <div className="message">
                {el.text}
                {this.imageView(el.image)}
                {this.imageView(el.arquivo)}
                <br />
                <span className="small-date">
                  {moment(el.createdAt).format("h:mm:ss a")}
                </span>
              </div>
            </div>
            {printViasualizado}
          </div>
        );
      });

      this.setState({
        hiddenOp: 1,
        content: conversa
      });
    }

    let positionSend = $(".content-send").offset();
    
    
   

    let heightChat = {
      height: positionSend
    };


  }

  receiveMessages(data) {
    switch (data.type) {
      case "insert":
        let newData = this.state.listaRaw.slice(0)
        newData.push(data.messages[0])
        newData = newData.filter((val, index, self) =>
          index === self.findIndex((v) => (
            v._id === val._id
          ))
        )

      this.setState({
        listaRaw: newData
      })
      this.handlerChat(newData)
      break
    }
  }

  getMessages(data){
      this.setState({
          listaRaw: data
      })

      this.handlerChat(data)
  }

  contentChat(data, e) {
    let self = this;
    let excludes = []
    if(this.refFirebase !== null) {
      this.refFirebase.off();
    }

    this.setState({
      active: data
    });


    this.listensMessages({_id:data._id, cnpj: data.cnpj}, this.receiveMessages, this.getMessages)
      Socket.getMessages(data._id, {
        type: "cnpj",
        cnpj: data.cnpj,
        _id: data._id,
        exclude: excludes
      })

    // active chat
    let btns = $(".open-chat");

    let activeChat = document.getElementsByClassName("open-chat");

    for (let i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function() {
        $(".open-chat").removeClass("actives");

        //  current.classList.remove("active");
        this.classList.add("actives");
      });
    }

    setTimeout(()=>{
      this.handleScrollFrame()
    },1000)
  }

  async renderStateList(lista, type= null) {

    let self = this
    let retorno

    if(lista != null && lista.length){
        lista.sort((a,b) => { 
          let data1 = new Date(b.updatedAt)
          let data2 = new Date(a.updatedAt) 

          return data1 - data2 
        })
        self.setState({
            dadosLista: lista
        })
    }


    if(type === null){
        if(lista === undefined || lista.length === undefined){
            return false
        }

        // cria o front para render
        retorno = lista.map((data, index) => {
            let seen = !data.seen.cnpj
            console.log(data)
            let classe = seen ? 'blue' : 'gray-icon'
            let grupo = data.group === true ? 'grupo' : ''
            

            //let name = data.users.length === 1? data.users[0].name : data.users.map(val => val.name).join(',')
            let name = data.users.length === 1? data.users[0].name : data.users[0].name + '...'
            let iconGroup = data.group === true ? <img src={IconGroup} height="46"/> : name.match(/\b\w/g).join('').substring(0, 2)
            let typeSend  = data.group === 'Lista' ? 'lista' : ''
            let iconList = data.group === 'Lista' ? "L" : ''
            let viewName = data.doc != '00000000000' ? <small className='name-msg'><b>{ name }</b></small> :  ''
            return (<li key={index}
                        className={"collection-item avatar open-chat hash "}
                        onClick={e =>{
                            self.contentChat(Object.assign(data), e)
                            //atualiza o seen
                            Socket.setChannel({
                                to: {
                                    cnpj: data.cnpj,
                                },
                                user: 'cnpj',
                                type: "update-seen",
                                _id: data._id,
                                group: this.props.url == '/criar-grupo' ? true : false
                            })
                            }
                        }>
                    <i className={"material-icons circle " + classe + " " + typeSend + " " + grupo  }>
                        <p>
                            {iconGroup}
                            <sub style={{ fontSize:9,  borderWidth: 1 }}>{iconList}</sub>
                        </p>
                    </i>
                    <Row>
                      <Col span={24} className="title">{data.subject}</Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        {viewName}
                    </Col>
                    <Col span={12} className='text-right'>
                        <small className="date-msg"><i>{moment(data.createdAt).format('DD/MM/YYYY')}</i></small>
                    </Col>
                      </Row>
                </li>)
        })
    } else if(type === 'insert'){
        let data = lista
        let seen = !data.seen.cnpj

        let classe = seen ? 'blue' : 'gray-icon'
        let grupo = data.group === '00000000000' ? 'grupo' : ''

        let name = data.users.length === 1? data.users[0].name : data.users.map(val => val.name).join(',')

        let typeSend  = data.group === 'Lista' ? 'lista' : ''
        let iconList = data.group === 'Lista' ? "L" : ''
        let viewName = data.doc != '00000000000' ? <small className='name-msg'><b>{ name }</b></small> : ""
        let insertArr = (<li key={this.state.lista.length + 1}
                            className={"collection-item avatar open-chat hash "}
                            onClick={e =>{
                                //atualiza o seen
                               
                                Socket.setChannel({
                                    
                                    to: {
                                        cnpj: data.cnpj,
                                    },
                                    user: 'cnpj',
                                    type: "update-seen",
                                    _id: data._id
                                  
                                })

                                self.contentChat(Object.assign(data), e)}
                            }>
                <i className={"material-icons circle " + classe + " " + grupo + " " + typeSend  }>
                    <p>
                        {name.match(/\b\w/g).join('').substring(0, 2)}
                        <sub style={{ fontSize:9,  borderWidth: 1 }}>{iconList}</sub>
                    </p>
                </i>
                <span className="title">{data.subject}</span>
                <p>
                    {viewName}
                </p>
                <p>
                    <small className="date-msg"><i>{moment(data.createdAt).format('DD/MM/YYYY')}</i></small>
                </p>
            </li>)

        retorno = this.state.lista.slice(0)
        retorno.unshift(insertArr)
    }

    self.setState({
        lista: retorno
    })
    
  
}

  sendMessagem(data, urlImage = null) {

    let message = {
        text: data,
        name: this.state.session.operador? this.state.session.operador.nome_empr_oprdr : this.state.active.employer,
        dataInfo: {
            id: this.state.session.operador ?  this.state.session.operador.id_empr_oprdr : this.state.session.entidade.id_entd,
            doc: this.state.session.operador ? this.state.session.operador.cpf_empr_oprdr :  this.state.session.entidade.cnpj_entd 
        },
        status: true,
        _ref: this.state.active._id,
        user: {
            _id: 0,
            name: this.state.session.operador? this.state.session.operador.nome_empr_oprdr : this.state.active.employer
        },
        seen: {
            cpf: false,
            cnpj: true
        }
    }

    //Vericando o tipo de arquivo caso seja enviado
      if(urlImage !== null){
        let typeFile = urlImage.split('.').pop();
        let onlyImg = ['jpeg','jpg','gif','png']
          if(onlyImg.indexOf(typeFile) > -1){
              message.image = urlImage
          } else {
              message.arquivo = urlImage
          }

      }

    let send = {
        _ref: this.state.active._id,
        user: 'cnpj',
        message: message,
        type: 'insert',
        name: this.state.session.operador? this.state.session.operador.nome_empr_oprdr : this.state.active.employer

    }

    

    Socket.setMessages(send)
    //TODO:: Listar docs com acesso ao grupo

    let listaPush = []
    this.state.active.users.map(resp => {
      listaPush.push(resp.cpf)
    })
    

    if (listaPush[0] != '00000000000'){
        Firebase.sendPush(listaPush, this.state.session.token, {message:send.name.toUpperCase() + " enviou uma nova mensagem."})
    }

  
    $("#mensagem").val("")
    $("#mensagem").blur()
    this.handleScrollFrame()
  }

  acronymName(data = "User Name") {
    return data
      .match(/\b\w/g)
      .join("")
      .substring(0, 2);
  }
  /**
   *
   * Apenas quando não haver nada na pesquisa
   */
  backRenderList(e) {

    let cnpj = null;
    let self = this;
    let inputValue = document.querySelector('input[name="inputSearch"]');

    if (this.state.session.type === "empr") {
     cnpj = this.state.session.empresa.cnpj_empr;
    } else {
      cnpj = this.state.session.entidade.cnpj_entd;
    }

    let channelsCurrent = this.state.dadosLista

    let result =  channelsCurrent.filter(item => {
        return item.subject.toLowerCase().indexOf(inputValue.value) > -1
    })

    if(inputValue.value != ""){
        self.renderStateList(result)
    } else {
        self.renderStateList(channelsCurrent)
    }
  }

  openFile = () => {
    let dd =  document.getElementById("bt_files")
    dd.click()
  }

  sendFile = () => {
    let el = document.querySelector("#bt_files")
    let formData = new FormData();
    formData.append('file', el.files[0]);

      fetch('https://cobranca.bsfonline.com.br/api-v1/uploads/images', {
          headers:{
              'Accept': 'application/json'
          },
            method: 'POST',
            body: formData
      })
      .then(resp => resp.json())
      .then(resp=> {
          this.sendMessagem(null, resp.location)
      } )

    /*this.PreviewFile(el.files[0]).then(data => {

        this.sendMessagem(data)
    })*/
  }

  PreviewFile = (file) => {
      return new Promise((s,f)=>{
          let reader = new FileReader()
          reader.onload = e => {
              s(reader.result)
          }
          reader.readAsDataURL(file)
      })
  }

  imageView = data =>{

    let regex = /(?:\.([^.]+))?$/;
    let ext = regex.exec(data)[1]

    if(ext == 'jpg' || ext == 'png' || ext == 'gif' || ext =='svg'){
        return (<a href={data}><img src={data} width="100%" /></a>)

    } else {
        if(data != undefined){

       let fileName = data.split('/')
        return (<p><a className='file-download' href={data}><Icon type="file" /> {fileName.pop()}</a></p>)
        }

    }
  }

  componentDidUpdate() {

    this.refs.scrollbars.refs.scrollTop = this.refs.scrollbars.scrollHeight - this.refs.scrollbars.offsetHeight;
  }

  receiveData(value){
        
        switch (value.type) {
          case "get":
            this.renderStateList(value.data)
            break

          case "insert":
              this.renderStateList(value.data, value.type)
              break

          /**
           * Atualiaza a lista de canais para o conecta
           */
          case "call-channels-get":
              let cnpj = null
              let self = this

              //Socket.getChannels(this.state.session.cnpj)
             if(this.props.url =='/criar-grupo'){
                Socket.getChannelsGroups(cnpj)
            } else {
                //Socket.getChannels(this.state.session.cnpj)
            }

              break
      }
  }

  componentDidMount() {
    let cnpj = null;
    let self = this;
    if (this.state.session.type === "empr") {
      cnpj = this.state.session.empresa.cnpj_empr;
    } else {
      cnpj = this.state.session.entidade.cnpj_entd;
    }

    //let instanceFB = Firebase.getConectionsListChats(cnpj);

    let retorno = [];

    // instanceFB.on("value", function(data) {
    //   try{
    //     self.renderStateList(data);
    //   } catch(e){

    //   }
    // });
    if(this.props.url =='/criar-grupo'){
        Socket.getChannelsGroups(cnpj)
    } else {
        Socket.getChannels(cnpj)
    }

    this.listensChannels(cnpj, this.receiveData)
    

    let result = $(".content-send").offset();
      // $(".chat-content").css("height", window.innerHeight);

    let current = document.getElementsByClassName("open-chat");

    $(window).resize(function() {
      let result = $(".content-send").offset();

        // $(".chat-content").css("height", result.top - 145.12);
    });

  }

  handleScrollFrame() {
    try {
      const { scrollbars } = this.refs.scrollbars.refs;
      const scrollHeight = scrollbars.getScrollHeight();
      setTimeout(() => {
        scrollbars.scrollTop(scrollHeight + 100);
      }, 600)
    } catch (error) {
      console.log(error)
    }
  }

  busca(e) {
    e.preventDefault();
    let valor = document.querySelector('input[name="inputSearch"]');
    let self = this;
    let regex = new RegExp(valor.value, "gim");
    let chatList = this.state.dadosLista;
    let resultado = [];

    for (let elem in chatList) {
      let mensagens = Object.keys(chatList[elem].payload).map(
        e => chatList[elem].payload[e]
      );
      let name = chatList[elem].employee;

      for (let ind in mensagens) {
        if (
          mensagens[ind].text.indexOf(valor.value) >= 0 ||
          name.match(regex)
        ) {
          resultado.push(chatList[elem]);
        }
      }
    }

    let render = resultado.map((data, index) => {
      let seen = true;

      if (data.seen.cnpj) {
        seen = false;
      }

      let classe = seen ? "blue" : "";
      let grupo = data.doc === "00000000000" ? "grupo" : "";
      let name = data.employee;
      let typeSend = data.typeSend === "Lista" ? "lista" : "";
      let iconList = data.typeSend === "Lista" ? "L" : "";
      let viewName =
        data.doc != "00000000000" ? (
          <small className="name-msg">
            <b>{name}</b>
          </small>
        ) : (
          ""
        );
      return (
        <li
          key={index}
          className={"collection-item avatar open-chat hash "}
          onClick={e => self.contentChat(Object.assign(data), e)}
        >
          <i
            className={
              "material-icons circle " + classe + " " + grupo + " " + typeSend
            }
          >
            <p>
              {name
                .match(/\b\w/g)
                .join("")
                .substring(0, 2)}
              <sub style={{ fontSize: 9, borderWidth: 1 }}>{iconList}</sub>
            </p>
          </i>

          <span className="title">{data.subject}</span>
          <p>{viewName}</p>
          <p>
            <small className="date-msg">
              <i>{moment(data.createdAt).format("DD/MM/YYYY")}</i>
            </small>
          </p>
        </li>
      );
    });

    //render new lista
    this.setState({
      lista: render
    });
  }

  limiteString = str =>{
      if(str){
          let limit = 50;
          return str.length > limit ? str.substring(0, limit - 3) + '...' : str
      }
  }

  render() {
    let { empresa, entidade } = this.state.session
    const { Header, Footer, Sider, Content } = Layout;

    //Ajustar content chat
    return (
      <Layout>
        <Sider  breakpoint={'xs', 'sm'}  className="col-users">
          <Col className="searchChat" span={24}>
            <form>
              <Input
                prefix={
                  <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Procurar conversa"
                name="inputSearch"
                onChange={this.backRenderList}
              />
            </form>
          </Col>
          <div className="over-list">
            <Scrollbars
             autoHide
              // Hide delay in ms
             autoHideTimeout={1000}
              // Duration for hide animation in ms.
             autoHideDuration={2000}
            >
              <Col span={24} className="chat-lista">
                  { this.state.lista.length > 0 ? ( <ul className="collection">{this.state.lista}</ul>) : (
                       <p className="no-message">SEM MENSAGENS</p>
                  ) }
              </Col>
              {this.props.url ==='/criar-grupo' ? (<Col span={24} className="buttons-group">
                  <Button  className="ant-btn ant-btn-primary" onClick={() => this.props.action.page(true)}>CRIAR GRUPO</Button>
              </Col>) : ""}
            </Scrollbars>
          </div>
        </Sider>
        <Content span={18} style={{ position: "relative" }}>
          <div id="chat" className="modal1 modal-fixed-footer">
            <div className="modal-content">
              <div className="content-table">
                <div
                  className="collection opacity headerFirst"
                  style={{ opacity: this.state.hiddenOp }}
                >
                  <div className="collection-item avatar topo-msg">
                    <i className="material-icons avatar-topo" >
                      {this.acronymName(this.state.active.employee)}
                    </i>
                    <p className="title"><b>{this.state.active.subject}</b> <br/>
                        {this.limiteString(this.state.active.employee)}<br/>
                        {this.limiteString(this.state.active.employer)}
                    </p>
                    <p><img className='buttonAttachment' onClick={()=>this.openFile()}  src={anexo}/></p>
                    <input type="file" id="bt_files" onChange={() => this.sendFile()}/>

                </div>
                  <div className="secondary-content">
                    <div
                      className="fixed-action-btn horizontal options"
                      style={{
                        display: "none"
                      }}
                    >
                      <a className="btn-floating btn-large red">
                        <i className="large material-icons">mode_edit</i>
                      </a>
                      <ul>
                        <li>
                          <a className="btn-floating red">
                            <i className="material-icons">insert_chart</i>
                          </a>
                        </li>
                        <li>
                          <a className="btn-floating yellow darken-1">
                            <i className="material-icons">format_quote</i>
                          </a>
                        </li>
                        <li>
                          <a className="btn-floating green">
                            <i className="material-icons">publish</i>
                          </a>
                        </li>
                        <li>
                          <a className="btn-floating blue">
                            <i className="material-icons">attach_file</i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <SpringScrollbars
                  className="chat-content"
                  ref="scrollbars"
                  horizontal={"false"}
                  renderTrackHorizontal={ () => <div style={{ display:'none'  }} ></div>}
                >
                  <div className="center-msg">{this.state.content}</div>
                </SpringScrollbars>
              </div>
            </div>
            <div className="content-send"
                style={{ opacity: this.state.hiddenOp }}
            >
              <textarea
                ref="mensagem"
                rows="2"
                cols=""
                className="modal-footer opacity"
                placeholder="Digite uma Mensagem"
                id="mensagem"
                style={{ opacity: this.state.hiddenOp }}
                onKeyPress={e => {
                  if (e.charCode === 13) {
                    this.sendMessagem(e.target.value);
                  }
                }}
              />
              <button
                className="btn-floating btn-large waves-effect waves-light red send opacity"
                style={{ opacity: this.state.hiddenOp }}
                id="send"
                onClick={e => {
                  this.sendMessagem(this.refs.mensagem.value);
                }}
              >
                <img src={ButtonSend}/>
              </button>
            </div>
          </div>
        </Content>
      </Layout>
    );
  }
}

function mapStateProps(state){
    return {
        page: state.navigation
    }
}

function mapDispatchProps (dispatch){
    return {
        action: bindActionCreators(navigationActions, dispatch)
    }
}

export default connect(mapStateProps, mapDispatchProps)(Chat);
