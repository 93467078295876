export default (state = [], payload) => {
    switch(payload.type){
        case 'INSERT_TRABALHADORES':
            state = payload.data
            return state
        case 'FIND_TRABALHADOR':
            let cpf = payload.data
            let resultTrabalhador = state.filter((el) => {
                if(cpf === el.cpf_tbldr){
                    return true
                }
            })
            return resultTrabalhador
        case 'FIND_LIST_TRABALHADORES':
            let cpfList = payload.data
            let resultTrabalhadores = state.filter((el) => {
                for(let i = 0; i < cpfList.length; i++){
                    if(el.cpf_tbldr === cpfList[i]){
                        return true
                    }
                }
            })

            return resultTrabalhadores
        default:
            return state
    }
}
