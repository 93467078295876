import React from "react";
import Base64 from "base-64";
import Cookie from "js-cookie";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CreateMessage from "./CreateMessage";
import CreateGroups from "../Components/CreateGroups.js";
import { Layout, Menu, Icon, Button, Radio, Switch, Col } from "antd";
import { Scrollbars } from "react-custom-scrollbars";

import $ from "jquery";

//ACTIONS
import * as TrabalhadoresActions from "../actions/trabalhadoresAction";
import * as TrabalhadoresSelectActions from "../actions/trabsSelectActions.js";
import * as SelectStateActions from "../actions/SelectStateAction.js";
import * as navigation from "../actions/navigationActions";

class Trabalhadores extends React.Component {
  constructor(props) {
    super(props);
    let session = {};
    if (this.props.entidade) {
      session = this.props.session;
    } else {
      session = JSON.parse(Base64.decode(window.localStorage.getItem("conecta_session")));
    }

    this.state = {
      trabalhadores: [],
      data: null,
      //toggleTrabs: false,
      listaSend: [],
      session: session
    };

    this.busca = this.busca.bind(this);
    this.onChange = this.onChange.bind(this);
    this.addTrabalhador = this.addTrabalhador.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.acronymName = this.acronymName.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentWillReceiveProps(props, oldProps) {
    this.setState({
      listaSend: props.selectTrabs
    });
  }
  async onChange(checked) {
    let self = this;
    let trabApp = this.state.trabalhadores;

    let cnpjParam = "";
    if (this.state.session.cnpj_empr) {
      cnpjParam = "?cnpj=" + this.state.session.cnpj_empr;
    }

    trabApp = trabApp.filter(elem => {
      return elem.tb_pltfm_infos != 0 ? true : false;
    });
    if (checked) {
      this.setState({
        trabalhadores: trabApp
      });
    } else {
      let res = await fetch(
        "https://api.bsfonline.com.br/trabalhadores" + cnpjParam,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: self.state.session.token
          }
        }
      );
      res = await res.json();

      self.setState({
        trabalhadores: res.content.empresa.tb_empr_tbldrs
      });
    }
  }
  async componentWillMount() {
    let self = this;
    $(".filled-in.trab-box").prop("checked", false);
    let cnpjParam = "";
    if (this.state.session.cnpj_empr) {
      cnpjParam = "?cnpj=" + this.state.session.cnpj_empr;
    }
    try {
      let res = await fetch(
        "https://api.bsfonline.com.br/trabalhadores" + cnpjParam,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: self.state.session.token
          }
        }
      );

      if (!res.ok) {
        throw Error("Erro na comunicação com o servidor.");
      }

      res = await res.json();
      self.props.action.insertTrabalhadores(res.content.empresa.tb_empr_tbldrs);

      self.setState({
        data: res.content,
        trabalhadores: res.content.empresa.tb_empr_tbldrs
      });
    } catch (err) {
      throw Error(err.message);
    }
  }

  busca(e) {
    let valor = e.target.value;
    let regex = RegExp(valor, "gim");
    let trabs = this.state.data.empresa.tb_empr_tbldrs;
    let resultado = [];

    $(".collection-item").hide();
    for (let i = 0; i < trabs.length; i++) {
      let busca = trabs[i].nome_tbldr + " " + trabs[i].cpf_tbldr;
      if (busca.match(regex)) {
        resultado.push(trabs[i]);
        $("[data-info='" + busca.replace(/[^\w\s]/gi, "") + "']").show();
      }
    }

    /*  this.setState({
            trabalhadores: resultado
        })*/
  }

  acronymName(data = "User Name") {
    return data
      .match(/\b\w/g)
      .join("")
      .substring(0, 2);
  }
  addTrabalhador(trab, event) {
    if (event.checked) {
      let arr = this.state.listaSend.slice(0);
      arr.push(trab);
      console.log("array>", arr);
      this.props.action.insertSelectTrabalhadores(arr);
    } else {
      let arr = this.state.listaSend.slice(0);
      let index = arr.indexOf(trab);
      arr.splice(index, 1);
      this.props.action.insertSelectTrabalhadores(arr);
    }
  }

  async selectAll(e) {
    let lista = [];
    let elements = null;

    if (this.props.toogleSelectTrabalhadore) {
      $(".filled-in.trab-box.is-trab").prop(
        "checked",
        !this.props.toogleSelectTrabalhadore
      );
      this.props.action.toogleState();
    } else {
      $(".filled-in.trab-box.is-trab").prop(
        "checked",
        !this.props.toogleSelectTrabalhadore
      );
      this.props.action.toogleState();
    }
    elements = $(".filled-in.trab-box:checked");
    await elements.map((index, el) => {
      lista.push(JSON.parse($(el).attr("json")));
    });
    this.props.action.insertSelectTrabalhadores(lista);
    this.setState({
      listaSend: lista
    });
  }
  handleSelect(e, el, index) {
    //Ao clicar no trabalhar ativar checkobox
    let self = this;
    let element = document.querySelector("#filled-in-box" + index);
    if (element.checked) {
      element.checked = false;
      element.parentElement.classList.remove("active");
    } else {
      element.checked = true;
      element.parentElement.classList.add("active");
    }
    self.addTrabalhador(el, element);
  }
  render() {
    let TrabalhadoresLista;
    let EntidadeModule = null;
    let ButtonSelectAll = null;

    if (this.state.data) {
      TrabalhadoresLista = this.state.trabalhadores.sort((a, b) => {
        if (a.nome_tbldr.toUpperCase() < b.nome_tbldr.toUpperCase()) return -1;
        if (a.nome_tbldr.toUpperCase() > b.nome_tbldr.toUpperCase()) return 1;
        return 0;
      });
      //TODO:: Lista apenas trabalhadores com APP
      //removendo quem não está com app
      // TrabalhadoresLista = TrabalhadoresLista.filter( (elem)=>{
      // 	return elem.tb_pltfm_infos != 0 ? true : false;
      // })

      TrabalhadoresLista = TrabalhadoresLista.map((el, index) => {
        let picture = (
          <i className="fa  circle" aria-hidden="true">
            {" "}
            {this.acronymName(el.nome_tbldr)}{" "}
          </i>
        );
        if (el.avatar_tbldr && el.avatar_tbldr !== "") {
          picture = <img src={el.avatar_tbldr} alt="" className="circle" />;
        }

        el.originalIndex = index;

        let retorno = (
          <li
            key={index}
            className="collection-item avatar"
            data-info={el.nome_tbldr + " " + el.cpf_tbldr}
            onClick={e => this.handleSelect(e, el, index)}
          >
            <input
              type="checkbox"
              className="filled-in trab-box is-trab"
              id={"filled-in-box" + index}
              value={el.cpf_tbldr}
              data={el.nome_tbldr}
              json={JSON.stringify(el)}
              onChange={e => this.addTrabalhador(el, e)}
            />
            <label htmlFor={"filled-in-box" + index} />
            {picture}
            {/* <span className="poiter-online active"></span> */}
            <span className="title">{el.nome_tbldr}</span>
            <p>
              <small>
                {el.fncao_tbldr} -{" "}
                {el.sexo_tbldr === "M" ? "MASCULINO" : "FEMININO"}
              </small>
            </p>
            {el.tb_pltfm_infos !== "0" ? (
              <i
                style={{ color: "#3ab5ef", fontSize: 35 }}
                className="fa fa-mobile"
                aria-hidden="true"
              />
            ) : (
              ""
            )}
          </li>
        );

        return retorno;
      });
    }

    if (this.props.entidade) {
      EntidadeModule = (
        <div className="empresa-info">
          <div className="empresa">
            <p>{this.state.session.empresa.razao_empr}</p>
          </div>
          <div className="voltar">
            <button
              className="ant-btn ant-btn-primary"
              onClick={e => this.props.changePage("empresa")}
            >
              <i class="material-icons">
keyboard_backspace
</i>
            </button>
          </div>
        </div>
      );
    }

    if (this.props.url !== "/criar-grupo") {
      ButtonSelectAll = (
        <div>
          <div className="card-title white-text"> SELECIONE TRABALHADORES </div>
          <button
            className="btn waves-effect waves-light ant-btn ant-btn-primary seleciona-todos btn-primary"
            name="action"
            onClick={this.selectAll}
          >
            SELECIONAR TODOS
            <i className="fa fa-check" aria-hidden="true">
              {" "}
            </i>
          </button>
        </div>
      );
    }
    const { Header, Content, Footer, Sider } = Layout;

    return (
      <Layout>
        <Sider className="col-users">
          <Content>
            <div className="card">
              <div className="card-constent">
                {EntidadeModule}
                <div className="button-top-send" />
              </div>
              <div className="title-cm">
                <p>
                  Para criar uma nova mensagem, você deve
                  <br /> <b>selecionar</b> um trabalhador.
                </p>
              </div>
              {this.props.url == "/criar-grupo" ? (
                <div className="back-list-group">
                  <Button
                    className="ant-btn ant-btn-primary"
                    onClick={() => this.props.action.page(false)}
                  >
                    VOLTAR 
                  </Button>
                </div>
              ) : (
                ""
              )}
              {/* ***** ÁREA DE BUSCA ****** */}
              <div className="busca-trabalhador">
                <div className="card-panel">
                  <Icon type="search" />
                  <input
                    type="text"
                    placeholder="Procurar um trabalhador"
                    id="search-trabalhadores"
                    onChange={this.busca}
                  />
                </div>
                <div />
              </div>
              {/* Filtro para quem já possui aplicativo instalado */}
              <div className="isAPP">
                <p>
                  {" "}
                  FILTRO:
                  <Switch onChange={this.onChange} />
                </p>
                <p>Exibir apenas trabalhadores com aplicativo instalado</p>
              </div>

              <Scrollbars className="criar-msg-scroll">
                <ul className="collection custom-list-trabalhadores">
                  {TrabalhadoresLista}
                </ul>
              </Scrollbars>
            </div>
          </Content>
        </Sider>
        <Content span={18} style={{ position: "relative" }}>
          <div>
            {/*ButtonSelectAll*/}
            {/* (this.props.url == '/lista-de-envio')?
            <CreateGroups trabalhadores={this.state.listaSend} session={this.props.session} url={this.props.url} /> : '' */}
            {this.props.url != "/lista-de-envio" ? (
              <CreateMessage
                trabalhadores={this.state.listaSend}
                session={this.props.session}
                url={this.props.url}
              />
            ) : (
              ""
            )}
          </div>
        </Content>
      </Layout>
    );
  }
}

function mapStateProps(state) {
  return {
    trabalhadores: state.trabalhadores,
    selectTrabs: state.trabsSelect,
    toogleSelectTrabalhadore: state.toogleSelectTrabalhadore
  };
}

function mapDispatchProps(dispatch) {
  return {
    action: bindActionCreators(
      Object.assign(
        TrabalhadoresActions,
        TrabalhadoresSelectActions,
        SelectStateActions,
        navigation
      ),
      dispatch
    )
  };
}

export default connect(
  mapStateProps,
  mapDispatchProps
)(Trabalhadores);
