export const getToggleState = () => {
    return {
        type: 'GET_TOGGLE_SELECT_TRABALHADORES'
    }
}

export const toogleState= () => {
    return {
        type: 'TOGGLE_SELECT_TRABALHADORES',
    }
}

