import React from "react";
import { Menu, Icon } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Layout, Row, Col, Button, Dropdown, Modal, Tabs, Badge } from "antd";
import $ from "jquery";
import Base64 from "base-64";


import Cookie from "js-cookie";

//actions
import * as TrabalhadoresActions from "../actions/trabalhadoresAction";
import * as TrabalhadoresSelectActions from "../actions/trabsSelectActions.js";
import * as SelectState from "../actions/SelectStateAction";

// SocketIO
import * as Socket from '../helpers/socket'

import { compose } from "recompose";

// Icons
import iconChat from '../_assets/img/envelope.png'
import iconMsg from '../_assets/img/chat.png'
import iconList from '../_assets/img/view-list.png'
import iconGroup from '../_assets/img/add-friend.png'

class MenuNavigation extends React.Component {
  constructor(props) {
    super(props);

    this.onlyEmp = this.onlyEmp.bind(this);
    this.showTabsNavigation = this.showTabsNavigation.bind(this);

    this.state = {
      session: JSON.parse(Base64.decode(Cookie.get("conecta_session"))),
      noRead: 0
    };

    this.listensChannels = Socket.listensChannels.bind(this)
    this.receiveData = this.receiveData.bind(this)

  }

  onlyEmp(iden) {
    if (iden === "empr") {
      return (
        <button onClick={() => this.props.history.push("/criar-grupo")}>
          <img src={iconGroup}/>
          MENSAGENS <br />EM GRUPO
        </button>
      );
    }
  }

  receiveData(value){
    console.log(value.type)
    switch(value.type){
      case "get":
        let LChannels = value.data
        LChannels = LChannels.filter( (index, val) => {
          return index.seen.cnpj === false ? true : false
        })
        this.setState({
          noRead: LChannels.length
        })
      break

      case "call-channels-get":
          let cnpj = null;

          if (this.state.session.type === "empr") {
            cnpj = this.state.session.empresa.cnpj_empr;
          } else {
            cnpj = this.state.session.entidade.cnpj_entd;
          }
        Socket.getChannels(cnpj)
      break

    }
  }

  componentDidMount(){

   

    let cnpj = null;
    let self = this;
    if (this.state.session.type === "empr") {
      cnpj = this.state.session.empresa.cnpj_empr;
    } else {
      cnpj = this.state.session.entidade.cnpj_entd;
    }
    this.listensChannels(cnpj, this.receiveData)
    Socket.getChannels(cnpj)
  }

  showTabsNavigation() {
    const TabPane = Tabs.TabPane;
    return (
      <div className="tabs-menu-header">
        <button onClick={() => this.props.history.push("/chat")}>
          <img src={iconChat}/>
          MENSAGENS <Badge className="badge-read" showZero={ false }style={{ backgroundColor: '#4ca92b', color: '#FFF', boxShadow: '0 0 0 1px #4ca92b inset'}} count={this.state.noRead}/>
        </button>
        <button onClick={() => this.props.history.push("/criar-mensagem")}>
          <img src={ iconMsg }/>
          CRIAR <br /> MENSAGENS
        </button>
        <button onClick={() => this.props.history.push("/lista-de-envio")}>
        <img src={ iconList }/>
          LISTA DE ENVIO
        </button>
        {this.onlyEmp(this.state.session.type)}
      </div>
    );
  }

  render() {
    return (
      <Col span={16} offset={4}>
        {this.showTabsNavigation()}
      </Col>
    );
  }
}

function mapStateProps(state) {
  return {
    trabalhadores: state.trabalhadores,
    selectTrabs: state.trabsSelect,
    toogleSelectTrabalhadore: state.toogleSelectTrabalhadore
  };
}

function mapDispatchProps(dispatch) {
  return {
    action: bindActionCreators(
      Object.assign(
        TrabalhadoresActions,
        TrabalhadoresSelectActions,
        SelectState
      ),
      dispatch
    )
  };
}

export default compose(
  withRouter,
  connect(
    mapStateProps,
    mapDispatchProps
  )
)(MenuNavigation);
