export const insertTrabalhadores = (trab) =>{
    return {
        type: 'INSERT_TRABALHADORES',
        data: trab
    }
}

export const getTrabalhadores = () => {
    return {
        type: 'GET_TRABALHADORES'
    }
}

export const findTrabalhador = (cpf) => {
    return {
        type: 'FIND_TRABALHADOR',
        data: cpf
    }
}

export const findListTrabalhadores = (cpfs) => {
    return {
        type: 'FIND_LIST_TRABALHADORES',
        data: cpfs
    }
}
