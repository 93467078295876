import { combineReducers } from 'redux'

import SelectState from './selectState.js'
import Trabalhador from './trabalhadoresReducer'
import Empresa from './EmpresasReducer.js'
import TrabalhadoresSelecionados from './trabsSelectReducer.js'
import Navigation  from './navigation'

const rootReducer = combineReducers({
    toogleSelectTrabalhadore: SelectState,
    trabalhadores: Trabalhador,
    trabsSelect: TrabalhadoresSelecionados,
    navigation:Navigation
})

export default rootReducer
