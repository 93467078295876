import React from 'react'
import Base64 from 'base-64'

import Chat from '../Components/Chat'

import Cookie from 'js-cookie'



import * as Firebase from '../helpers/firebase'

class ConectaChat extends React.Component {
    constructor(props){
        super(props)
        this.trabalhadoresRef = null

        this.state = {
            session: {},
            createMessage: true,
            listTrabalhadores: []
        }
    }

    componentDidMount(){
        this.setState({
            session: JSON.parse(Base64.decode(window.localStorage.getItem("conecta_session")))
        })
        let cnpj = this.state.session.cnpj

        Firebase.addPushToken(cnpj, this.state.session.token)
    }

    render(){
       

        return (<div className="content-page">
            <div className="conecta">      
                <Chat />
            </div>
        </div>)
    }
}

export default ConectaChat
