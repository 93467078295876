import React from 'react'
import {  Button } from 'react-materialize'
import { Modal } from 'antd';
import * as Firebase from '../helpers/firebase.js'
import $ from 'jquery'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import * as Socket from '../helpers/socket'

//ACTIONS
import * as TrabalhadoresActions from '../actions/trabalhadoresAction.js'
import * as TrabalhadoresSelectActions from '../actions/trabsSelectActions.js'

class CreateGroups extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            toggle:   false,
            redirect: false,
            popUp:    false
        }
        console.log(this.props)
        this.criarGrupos    = this.criarGrupos.bind(this)
        this.criarLista     = this.criarLista.bind(this)
        this.removeUserList = this.removeUserList.bind(this)
        this.viewButtonList = this.viewButtonList.bind(this)
        this.modalSuccess = this.modalSuccess.bind(this)
    
    }

    removeUserList(e){
        let index =  e.target.getAttribute('index')
        let arr = this.props.selectTrabs.slice(0)
        console.log(arr[index], arr, index)
        $("#filled-in-box" + arr[index].originalIndex).prop("checked", false)

        arr.splice(index, 1)
        this.props.action.insertSelectTrabalhadores(arr)

        if(arr.length < 2){
            $('#box-send-group').modal('close')
        }
    }

    criarGrupos(e){
        e.preventDefault()
        let data = {
            name: e.target.name.value,
            description: e.target.description.value,
            cnpj: this.props.session.cnpj,
            employees: []
        }

        let result = Firebase.createGroup(this.props.selectTrabs, data)

        if(result){
           // $('#box-send-group').modal('close')
              $('.modal-close').trigger('click')
             this.setState({
                 redirect : true
             })

        }
    }

    modalSuccess () {
       return Modal.success({
            title:   'Sucesso',
            content: 'sua lista foi criada com Sucesso!'
        })
    }

    criarLista(e){
        e.preventDefault()
        let channel ={
            group:    (this.props.url ==='/criar-grupo')? true : false ,
            list:     (this.props.url ==='/lista-de-envio') ? true : false,
            seen:     {
                cnpj: true,
                cpf:  false
            },
            subject:     e.target.name.value, //titulo da Lista
            description: e.target.name.value //descricao
        }
        let docs = []
        if(this.props.session.type === 'empr'){
            let empr         = this.props.session.empresa
            channel.cnpj     = empr.cnpj_empr
            channel.employer = empr.razao_empr
        } else {
            let entd         = this.props.session.entidade
            channel.cnpj     = entd.cnpj_entd
            channel.employer = entd.razao_entd
        }
        let dataArr = {
            insert: [],
            message: '',
            type: 'insert-batch'
        }

        let users = []

        this.props.selectTrabs.map((el, index) => {
            let channelEl

            if (typeof el === 'number') {
                /*channelEl = Object.assign({
                    users: [{
                        cpf: index.cpf_tbldr,
                        name: index.nome_tbldr
                    }],
                }, channel)*/
                users.push({
                    cpf: index.cpf_tbldr,
                    name: index.nome_tbldr

                })
                docs.push(index.cpf_tbldr)
            }else{

                users.push({
                    cpf: el.cpf_tbldr,
                    name: el.nome_tbldr

                })
                docs.push(el.cpf_tbldr)
            }

        })
            //dataArr.insert.push(channelEl)
        channel.users = users

        dataArr.insert.push(channel)
        Socket.setChannel(dataArr)

        this.props.action.clearSelectTrabalhadores()  //limpando selecionados
        //Clear input
       e.target.name.value = ""
       e.target.description.value =""
       $('#lista-grupo').trigger("click")
       this.modalSuccess()
    }

    viewButtonList(url){
        if(url ==='/criar-grupo'){
            return {display:'none'}
        } else {
            return {display:'inline'}
        }
    }

    render(){
        if(this.state.redirect){
            return <Redirect to='/lista-de-envio' / >
        }
        let liberaGrupos = this.props.selectTrabs.length < 2? true : false

        let paraGroup = this.props.selectTrabs.map((el, el2) => {
        if(typeof el === 'number'){
            return <span key={ el } 
                className='to-single badge blue white-text cursor' 
                title={ el2.cpf_tbldr }>
                { el2.nome_tbldr }
                <i onClick={this.removeUserList} index={ el } className="fa fa-times-circle" aria-hidden="true"></i>
            </span>
        }else{
            return <span key={ el2 } className='to-single badge blue white-text cursor' title={ el.cpf_tbldr }>
                { el.nome_tbldr }
                <i onClick={this.removeUserList} index={ el2 } className="fa fa-times-circle" aria-hidden="true"></i>
            </span>
        }
    })

        return <div className="conteudo-grupos">
            <div
                id="box-send-group"
                trigger = { <Button className = "ant-btn ant-btn-primary btn-primary"
                    style= {this.viewButtonList(this.props.url)}
                    disabled = {
                            liberaGrupos
                        }>
                        CRIAR LISTA DE ENVIO + </Button> }>
                    <h6>
                        CRIAR LISTA DE ENVIO
                    </h6>
                    <label className='label-to'>Para: <span className="total">{ this.props.selectTrabs.length }</span></label>
                    <div className="to">
                        { paraGroup }
                    </div>
                    <form action="#" id="form-create-group" onSubmit={this.criarLista}>
                        <input type="text" placeholder="Nome da Lista" id="assuntr-all" required="" name="name"/>
                        <textarea id="obs" placeholder="Descrição do Lista" name="" cols="30" rows="10" name="description" requierd=""></textarea>
                        <button className="ant-btn ant-btn-primary" type="submit">CRIAR LISTA</button>
                    </form>
                </div>
            </div>
            }
            }

    function mapStateProps(state){
    return {
        trabalhadores: state.trabalhadores,
        selectTrabs: state.trabsSelect
    }
}


function mapDispatchProps(dispatch){
    return {
        action: bindActionCreators(Object.assign(TrabalhadoresActions, TrabalhadoresSelectActions), dispatch)
    }
}

export default connect(mapStateProps, mapDispatchProps)(CreateGroups)
