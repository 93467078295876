import {
    createStore,
    applyMiddleware,
    compose} from 'redux'
import thunk from 'redux-thunk'
import rootPath from './reducers'

/**
 * Cria o store que faz o transporte das informações do recucers(na pasta , no caso ela lê o index).
 */
export default (initialState) => {
    // return createStore(rootPath, initialState);
    return createStore(rootPath, initialState, compose(
        applyMiddleware(thunk),
        window.devToolsExtension ? window.devToolsExtension() : f => f
    ))
}
