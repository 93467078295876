export const insertEmpresa = (data) => {
    return {
        type: 'INSERT_EMPRESA',
        data: data
    }
}

export const getEmpresa = () =>{
    return {
        type: 'GET_EMPRESA'
    }
}

export const clearEmpresa = () => {
    return {
        type: 'CLEAR_EMPRESA'
    }
}
