import { string } from 'prop-types';

export const paginateArray = (arr, pageSize, pageNumber) => {
    return arr.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize)
}

export const totalPage = (total, pageSize) => {
    let totalPages = Math.ceil(total / pageSize) - 1
    return totalPages
}

export const listaNumPages = (paginate, total, arr) => {
    if(paginate === 1 || paginate === 2){
        return arr.slice(0, 5)
    }else if(paginate === total || paginate === (total - 1)){
        return arr.slice(total - 5, total + 2)
    }

    return arr.slice(paginate - 3, paginate + 2)
}

export const nameCharLimit  =  (str) => {
    if(str && str.length > 0){
        return str.substr(0, 35)
    } else {
        return str
    }
}
