import React from 'react'
import Base64 from 'base-64'

import Trabalhadores from '../Components/Trabalhadores'
import Cookie from 'js-cookie'
import SelectEmpresa from '../Components/SelectEmpresa.js'
import TrabalhadorEntidade from '../Components/EntidadeTrabalhadorBusca'
//import GrupoSend from '../Components/GruposSend.js'
import { Layout, Menu, Icon, Button, Radio, Modal, Row, Col } from 'antd';
import * as Firebase from '../helpers/firebase'
import $ from 'jquery'
import Sider from 'antd/lib/layout/Sider';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

//ACTIONS
import * as TrabalhadoresActions from '../actions/trabalhadoresAction'
import * as TrabalhadoresSelectActions from '../actions/trabsSelectActions.js'
import * as SelectStateActions from '../actions/SelectStateAction.js'


class Perfil extends React.Component {
    constructor(props) {
        super(props)
        this.trabalhadoresRef = null

        this.state = {
            session: JSON.parse(Base64.decode(window.localStorage.getItem("conecta_session"))),
            createMessage: true,
            listTrabalhadores: []
        }

        this.listarTrabalhadores = this.listarTrabalhadores.bind(this)
        this.props.action.clearSelectTrabalhadores()
    }

    componentDidMount() {
        let cnpj = this.state.session.cnpj

        //Firebase.addPushToken(cnpj, this.state.session.token)
    }


    componentWillMount(){

    	this.listarTrabalhadores();

    }

    async listarTrabalhadores(){

    	let res = await fetch('https://api.bsfonline.com.br/empresas', {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.session.token
                }
            })
            if(!res.ok){
                throw Error("Erro na comunicação com o servidor.")
            }
            res = await res.json()




    }

    render() {

        let perfil = null
        if (this.state.session.type === 'empr') {
            perfil = <Trabalhadores 
                ref={instance => this.trabalhadoresRef = instance} 
                session={this.state.session} 
                url={this.props.location.pathname} />
        } else {
            perfil = <SelectEmpresa 
                        session={this.state.session}  
                        url={this.props.location.pathname}/>
        }

        //let buscaTrabalhador = (this.state.session.type != 'empr') ?  : ''

        return (
            <Layout>
                <div className="content-page">
                    <div id="seleciona-trabalhadores" className="col s12">
                        {perfil}
                    </div>
                </div>
            </Layout>
        )

    }
}

function mapStateProps(state){
    return {
        trabalhadores: state.trabalhadores,
        selectTrabs: state.trabsSelect,
        toogleSelectTrabalhadore: state.toogleSelectTrabalhadore
    }
}

function mapDispatchProps(dispatch){
    return {
        action: bindActionCreators(Object.assign(TrabalhadoresActions, TrabalhadoresSelectActions, SelectStateActions), dispatch)
    }
}

export default connect(mapStateProps, mapDispatchProps)(Perfil)
