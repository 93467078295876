export default (state = false, payload) => {
    switch(payload.type){
        case 'TOGGLE_SELECT_TRABALHADORES':
            state = !state

            return state
        case 'GET_TOGGLE_SELECT_TRABALHADORES':
            return state
        default:
            return state
    }
}
