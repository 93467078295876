import firebase from 'firebase'
import uuidV4 from 'uuid/v4'
import Cookie from 'js-cookie'
import Base64 from 'base-64'



//

const session = JSON.parse(Base64.decode(window.localStorage.getItem("conecta_session")))

const config = {
    apiKey: "AIzaSyCHvahxPOqz05KbYAn2weTo_TCDIzLpvTw",
    authDomain: "bsf-connecta.firebaseapp.com",
    databaseURL: "https://bsf-connecta.firebaseio.com",
    projectId: "bsf-connecta",
    storageBucket: "bsf-connecta.appspot.com",
    messagingSenderId: "257892754116",
    appId: "1:257892754116:web:98fc42f8276ecad59ddf91",
    measurementId: "G-VSS0DZF8SH"
}


firebase.initializeApp(config)
var serviceAccount = require('../../src/adminFirebase.json');


firebase.auth().signInWithCustomToken(session.firebase)




const database = firebase.database()


let messaging = null
//Problemas com navegadores que nao tem suporte
if(firebase.messaging.isSupported()){
	messaging = firebase.messaging()
	
	messaging.onMessage(function(payload) {
	    console.log(payload)
	    Notification.requestPermission();
	    new Notification( payload.notification.title,{
	           body: payload.notification.body,
	           icon: payload.notification.icon,
	    });
	})

}




export const createMessage = (data) => {
    //const momentDate = moment().locale('pt_BR').format()
    let operador = null
    if(session.operador && session.operador.nome_empr_oprdr){
        operador =  {
            _id: session.operador.id_empr_oprdr,
            doc: session.operador.cpf_empr_oprdr,
            nome: session.operador.nome_empr_oprdr,
            email: session.operador.email_empr_oprdr
        }
    }

    const momentDate = firebase.database.ServerValue.TIMESTAMP
    const refs = database.ref('chats/' + data.cnpj)
    let key = refs.child(data.doc).push({
        subject: data.subject,
        employee: data.employee,
        employer: data.employer,
        typeSend:data.typeSend || null,
        doc: data.doc,
        cnpj: data.cnpj,
        group: data.group || null,
        seen: {
            cnpj: true,
            cpf:  false
        },
        createdAt: momentDate,
        updatedAt: momentDate,
        status: true,
        payload: []
    }).key

    let dados = {
        index: 1,
        text : data.message,
        operator: operador,
        createdAt: momentDate,
        status: false,
        _id : uuidV4(),
        user : {
            name: data.employer,
            _id: 0
        }
    }

    refs.child(data.doc + '/' + key + '/payload').push(dados)

    if(key){
        return key
    }else{
        return false
    }
}

export const getDatabaseTimeStamp = () => {
    return firebase.database.ServerValue.TIMESTAMP
}

export const sendPush = async (docs, token, info = {}) => {
    try{
        let res = await fetch('https://api.bsfonline.com.br/push/send', {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            body: JSON.stringify({
                docs: docs,
                title: info.title? info.title : 'BSF CONECTA',
                message: info.message? info.message : 'Você recebeu uma nova mensagem.'
            })
        })

        if(!res.ok){
            throw Error("Erro na cominicação com o servidor.")
        }

        res = await res.json()
    }catch(err){
        //alert( err.message )
    }
}

export const addPushToken = (cnpj, tokenAuth) => {

    let permissionToken = Cookie.get('_conID')
    if(firebase.messaging.isSupported()){
    messaging.requestPermission()
        .then(function( ){
            return messaging.getToken()
        })
        .then(async function(token){
            let send = {}
            if(permissionToken && JSON.parse(permissionToken).number === session.cnpj){
                //finish new  request token
                permissionToken = JSON.parse(permissionToken)
                send = {
                    typeInsert: 'update',
                    pushToken: token,
                    uuid: permissionToken._id,
                    platform: navigator.userAgent,
                    so: navigator.platform,
                    phone: '000000000000',
                    type: 'web',
                    cnpj: cnpj,
                    version: navigator.appVersion,
                    isValid: navigator.onLine,
                    model: 'BROWSER'
                }
            }else{
                //delete cookie
                Cookie.remove('_conID')

                send = {
                    typeInsert: 'insert',
                    pushToken: token,
                    platform: navigator.userAgent,
                    uuid: uuidV4(),
                    so: navigator.platform,
                    phone: '000000000000',
                    type: 'web',
                    cnpj: cnpj,
                    version: navigator.appVersion,
                    isValid: navigator.onLine,
                    model: 'BROWSER'
                }

                Cookie.set('_conID', JSON.stringify({
                    _id: send.uuid,
                    number: session.cnpj
                }))
            }

            try{
                let res = await fetch('https://api.bsfonline.com.br/tokens', {
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + tokenAuth
                    },
                    method: 'POST',
                    body: JSON.stringify(send)
                })

                if(!res.ok){
                    throw Error("Erro na cominicação com o servidor.")
                }

                res = await res.json()
            }catch(err){}

        })
        .catch(function(err) {
            console.log('Unable to get permission to notify.', err)

        })
    }
}

export const getConectionsListChats = (cnpj) => {
    return database.ref('chats/' + cnpj)
}

export const getChatActive = (cnpj, doc, key, extra = '') => {
    let db = firebase.database()
    return db.ref('chats/' + cnpj).child(doc + '/' + key + extra)
}

export const getDocs = async(cnpj, key) => {
    console.log('o KEY',key)
    let re = []
    const ref = firebase.database().ref('groupChat')
    const filter = ref.orderByChild(cnpj).startAt(cnpj)
    const result = await filter.once('value')
    const dados = result.val()
    const docs = []

    Object.keys(dados).map((value, index) => {
        let filter = dados[value]

        Object.keys(filter).map( (v, i)=>{

            let idGrupo = Object.keys(filter[v]['00000000000'])


            if(idGrupo.indexOf(key)  > 0){
                docs.push(value)
            }

        })
    })

    console.log(docs)
  //  const find = re.forEach()


    //Retorna um array unique
    return [...new Set(docs)]

}

export const updateChat = (cnpj, doc, key) => {
    let db = firebase.database()
    let update = {}
    update[`chats/${cnpj}/${doc}/${key}/updatedAt`] = firebase.database.ServerValue.TIMESTAMP
    update[`chats/${cnpj}/${doc}/${key}/seen`] = {
        cnpj: true,
        cpf: false
    }
    db.ref().update(update)
}

export const updateSeen = (cnpj, doc, key, data) => {
    let db = firebase.database()
    let update = {}
    update[`chats/${cnpj}/${doc}/${key}/seen/cnpj`] = true
    db.ref().update(update)
}


/*
 * BSF GRUPOS
 */
export const createGroup = (listTrabalhadores, data) => {
    const db = firebase.database().ref(`/grupos`)
    let insert = {
        cnpj: data.cnpj,
        name: data.name,
        description: data.description,
        employees: []
    }

    let result = db.child(data.cnpj).push(insert).key
    listTrabalhadores.map((el, index) => {
        let employee = el
        db.child(`${data.cnpj}/${result}/employees`).push(employee)
    })
    return result
}

export const getGroupList = (cnpj) => {
    let db = firebase.database()
    return db.ref(`/grupos`).child(cnpj)
}
// No momento so funciona para empresa
export const createGroupChat = (data,key) => {

    const momentDate = firebase.database.ServerValue.TIMESTAMP
    let dados = {
        created: momentDate,
        status: true
    }
    let insert = database.ref('groupChat/' + data.doc + '/' + data.cnpj + '/00000000000/' + key).push(dados)
    return insert;

}
